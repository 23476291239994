import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { set, useForm } from "react-hook-form";
import { Margin, usePDF } from 'react-to-pdf';
import { Tooltip } from 'react-tooltip'
import { mask, unMask } from 'remask';
import 'react-tooltip/dist/react-tooltip.css';
import moment from "moment";
import 'moment/locale/pt-br';


import * as C from './styled'
import * as G from '../../styles/global'
import * as M from '../../styles/modal'


import Api from '../../services/api'
import { UserContext } from '../../contexts/UserContext';


import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowOutwardOutlinedIcon from '@mui/icons-material/ArrowOutwardOutlined';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import BusinessIcon from '@mui/icons-material/Business';
import CalculateIcon from '@mui/icons-material/Calculate';
import CancelIcon from '@mui/icons-material/Cancel';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import CloseIcon from '@mui/icons-material/Close';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import CopyrightIcon from '@mui/icons-material/Copyright';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import EditIcon from '@mui/icons-material/Edit';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import HelpIcon from '@mui/icons-material/Help';
import NotStartedIcon from '@mui/icons-material/NotStarted';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PaidIcon from '@mui/icons-material/Paid';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import Person2Icon from '@mui/icons-material/Person2';
import Person4Icon from '@mui/icons-material/Person4';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import ReplyIcon from '@mui/icons-material/Reply';
import SearchIcon from '@mui/icons-material/Search';
import SpatialAudioOffIcon from '@mui/icons-material/SpatialAudioOff';
import SpatialTrackingIcon from '@mui/icons-material/SpatialTracking';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import TaskIcon from '@mui/icons-material/Task';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';

import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

import DateRangeIcon from '@mui/icons-material/DateRange';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { borderRadius } from '@mui/system';




export const Processes = () => {

  const navigate = useNavigate();

/// USEFORM ////////////////////////////////////////////////////////////////////

  const {
    register, 
    handleSubmit, 
    reset, 
    setValue, 
    unregister, 
    watch, 
    formState: { errors }
  } = useForm({ 
    mode: 'all', 
    // defaultValues: {
    //   proc_numero: '',
    // }
  });


////////////////////////////////////////////////////////////////////////////////


  const { state: user } = useContext(UserContext)
  const [searchParams] = useSearchParams()

  const ref = useRef([]);

  moment.locale('pt-br')


  const [tokens, setTokens] = useState(false)

  const [list, setList] = useState([]);
  const [filteredList, setFilteredList] = useState([])
  const [fasesProcessoList, setFasesProcessoList] = useState([])
  const [switchButton, setSwitchButton] = useState(false)
  const [state, setState] = useState('')
  const [empty, setEmpty] = useState(null)
  const [loading, setLoading] = useState(false)
  const [loadingModal, setLoadingModal] = useState(false)
  const [modal, setModal] = useState(false)
  const [payModal, setPayModal] = useState(false)
  const [isDownload, setIsDownload] = useState(false)
  const [showClose, setShowClose] = useState(false)
  const [selectedProcess, setSelectedProcess] = useState('')
  const [selectedProcessType, setSelectedProcessType] = useState('')
  const [showAddComments, setShowAddComments] = useState(false)
  const [addComments, setAddComments] = useState('')
  const [convertedFile, setConvertedFile] = useState([])
  const [acts, setActs] = useState('')
  const [actsDetails, setActsDetails] = useState('')
  const [actsFull, setActsFull] = useState('')
  const [actsPainel, setActsPainel] = useState(false)
  const [delPhaseSelected, setDelPhaseSelected] = useState(false)
  const [delProcessSelected, setDelProcessSelected] = useState(false)


  const [addProcessPhase, setAddProcessPhase] = useState(false)
  const [prevValue, setPrevValue] = useState('R$ 0,00')

  const [faseEntrada, setFaseEntrada] = useState('');
  const [fasePrazo, setFasePrazo] = useState('');
  
  const [valuePay, setValuePay] = useState('')
  const [prevPay, setPrevPay] = useState(false)
  const [userProcess, setUserProcess] = useState(false)
  const [payModalPdf, setPayModalPdf] = useState(false)
  const [clientPay, setClientPay] = useState('')
  const [selectedPayEntity, setSelectedPayEntinty] = useState('')
  const [selectedPayDate, setSelectedPayDate] = useState('')
  const [selectedPayType, setSelectedPayType] = useState('')

  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()

  const [comments, setComments] = useState('')
  const [comentarios, setComentarios] = useState([]);
  const [atividades, setAtividades] = useState([]);
  const [selectedActs, setSelectedActs] = useState([])
  
  const [company, setCompany] = useState([])
  const [lawyer, setLawyer] = useState([])
  const [payTypes, setPayTypes] = useState([])
  const [users, setUsers] = useState([])
  const [phase, setPhase] = useState([])
  const [selectedPhase, setSelectedPhase] = useState([])
  const [activityPhase, setActivityPhase] = useState([])

  const [stateErrors, setStateErros] = useState(false);

  

/// PROCESS NUMBER MASK ////////////////////////////////////////////////////////

const [processNumber, setProcessNumber] = useState('')

const onChange = ev => {
  
  const originalValue = unMask(ev.target.value);
  const maskedValue = mask(originalValue, ['9999999-99.9999.9.99.9999']);

  setProcessNumber(unMask(maskedValue));


}

////////////////////////////////////////////////////////////////////////////////



  const { toPDF, targetRef } = usePDF({
    method: "save",
    filename: clientPay+'_'+startDate+'_'+endDate,
    page: { margin: Margin.NONE },
  });


  const GoClick = () => {
    navigate('/Archive');
  }


  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase();
    let result = [];
    result = list.filter((data) => {
      return data.proc_reclamante.toLowerCase().search(value) !== -1
      || data.proc_reclamada.toLowerCase().search(value) !== -1 
      || data.proc_identificacao.toLowerCase().search(value) !== -1 
      || data.proc_id.toString().search(value) !== -1; 
    });
    setFilteredList(result);
  }


  const RefreshClick = (typeProcess) => {
    // document.getElementById('search').value = '';
    // setFilteredList([])
    setState(typeProcess)
  }


  const ProcessDetails = async (key, processCode, processType) => {
    setLoadingModal(false)
    setSelectedProcess(key)
    setSelectedProcessType(processType)
    setProcessNumber(filteredList[key].proc_numero);
    let res = await Api.getCompany()
    setCompany(res)
    let res1 = await Api.getLawyer()
    setLawyer(res1)
    let res2 = await Api.getUser()
    setUsers(res2)
    let res3 = await Api.getPhase()
    setPhase(res3)
    let res4 = await Api.getProcessPhase(processCode);
    setFasesProcessoList(res4.sort(function(a, b){return new Date(b.fase_proc_data_entrada) - new Date(a.fase_proc_data_entrada)}))
    setModal(true)
  }


  const PayFilter = (value, startDateValue, endDateValue) => {
    let result = [];
    let valueIf = !value ? 'não encontrado' : value
    result = list.filter((data) => {
      return (
        data.adv_nome.search(valueIf) !== -1
        || data.emprs_nome.search(valueIf) !== -1
      ) 
        && (new Date(data.fase_proc_data_saida) >= new Date(startDateValue) && new Date(data.fase_proc_data_saida) <= new Date(endDateValue)) 
    }) 
    //console.log("PayFilter > data : ", value);
    setFilteredList(result);
  }


  const PayClick = async (key, processCode) => {
    setLoadingModal(false)
    let res1 = await Api.getCompany()
    setCompany(res1)
    let res2 = await Api.getLawyer()
    setLawyer(res2)
    let res3 = await Api.getPaymentMethod()
    setPayTypes(res3)
    PayFilter()
    setPayModal(true)
  }

  
  const PayClickConfirm = async () => {
    var receipts = [];
    for(let elemento of filteredList){
      receipts.push(elemento.proc_id)
      receipts.push(elemento.proc_identificacao);
    }

    var process = [];
    for(let elemento of filteredList){
      process.push(elemento.proc_id)
    }

    var phases = [];
    for(let elemento of filteredList){
      phases.push(elemento.ctrl_atvd_fase_id)
    }

    let res = await Api.addReceipt(
      {
        rcbmt_data_vencimento: selectedPayDate,
        rcbmt_descricao: 'Honorários de Cálculos Trabalhista',
        rcbmt_entidade_nome: selectedPayEntity,
        rcbmt_entidade_id: 5,
        rcbmt_forma_pagamento: selectedPayType,
        rcbmt_valor_bruto: filteredList.reduce((a, b) => a + parseFloat(b.fase_proc_valor_recebido), 0),
        rcbmt_observacao: receipts.toString(),
        rcbmt_categoria: 3,
        rcbmt_estado: 1,
        rcbmt_usuario_criacao: user.id,
        rcbmt_usuario_atualizacao: user.id
      }
    )

    phases.forEach(async function(nome) {
      let res = await Api.upgProcessPhase({
        //"proc_estado_id": 3,
        "fase_proc_estado": 3,
        "fase_proc_id": nome,
        "fase_proc_usuario_atualizacao": user.id
      })
    });

    process.forEach(async function(nome) {
      let res = await Api.upgProcess({
        "proc_estado_id": 2,
        "proc_etapa": 6,
        "proc_id": nome,
        "proc_atualizacao_usuario": user.id
      })
    });

    ClosePayModal()
  }


  const GetSelect =  async (value) => {
      let res = await Api.getPhase()
      //console.log("GetSelect > res: ", res)
      setPhase(res)
  }
  

  const GetActivityPhase =  async (value) => {
      setSelectedPhase(value)
      let res = await Api.getActivityPhase(value)
      //console.log("GetActivityPhase > res: ", res)
      setActivityPhase(res)
  }


  // const AddProcessPhase = async () => {
  //   let res1 = await Api.addProcessPhase(filteredList[selectedProcess].proc_id, selectedPhase)
  //   //console.log("AddProcessPhase > res1 : ", res1)
  //   let processPhase = res1.toString()
  //   let atvsData = activityPhase.map(i => ({...i, ctrl_atvd_fase_id: processPhase})) 
  //   //console.log("addProcessPhase > atvsData : ", atvsData)
  //   let res2 = await Api.addCtrlActivity(atvsData)
  //   setAddProcessPhase('')
  //   setModal(false)
  //   setActivityPhase('')
  // };


  const AddProcessPhase = async () => {
    let res = await Api.addProcessPhase(
      {
        "fase_proc_fase_id": selectedPhase,
        "fase_proc_processo_id": filteredList[selectedProcess].proc_id,
        // "fase_proc_data_entrada": addProcessPhaseDate,
        "fase_proc_data_entrada": faseEntrada,
        "fase_proc_data_pazo": fasePrazo,
        "fase_proc_usuario_atualizacao": user.id
      })
    let processPhase = res.toString()
    let atvsData = activityPhase.map(i => ({...i,ctrl_atvd_fase_id: processPhase})) 
    let res2 = await Api.addCtrlActivity(atvsData)
    let res3 = await Api.upgProcess(
      {
        "proc_etapa": 1,
        "proc_estado_id": 1,
        "proc_id": filteredList[selectedProcess].proc_id,
        "proc_atualizacao_usuario": user.id
      })
    setAddProcessPhase('')
    setModal(false)
    setActivityPhase('')
  };


  const CloseModalClick = () => {
    setModal(false)
    setSelectedProcess('')
    setSelectedProcessType('')
    setProcessNumber('');
    getCardProcess();
  }


  const ClosePayModal = () => {
    setPayModalPdf(false)
    setPayModal(false)
    setPrevPay(false)
    getCardProcess();
    setSelectedPayDate('')
    setSelectedPayEntinty('')
    setSelectedPayType('')
    setClientPay('')
    setStartDate('')
    setEndDate('')
    setProcessNumber('');
  }


  const getCardProcess = async () => {
    setEmpty(false)
    setFilteredList([])
    setLoading(true)
    let res2 = await Api.getCardProcess(
      state === '1' ? 'tipos[]=1' : 
      state === '2' ? 'tipos[]=2' : 
      state === '3' ? 'tipos[]=3' : 'tipos[]=1&tipos[]=2&tipos[]=3',
      prevPay ? '&etapa=2' : '&etapa=1',
      switchButton ? `&userid=${user.id}`: actsPainel ?`&userid=all` : null
    );
    //console.log("getCardProcess > res2 : ", res2)
    if (res2.length > 0) {
      setEmpty(false)
      setList(res2)
      setFilteredList(res2)
    } else {
        setEmpty(true)
    }
    setLoading(false);
  }


  const GetComentarios = async (key, idFase) => {
    // setLoading(true)
    
    let res = await Api.getComment(idFase);
    if (res) {
      setComentarios(res.sort(function(a, b){return new Date(b.cmtr_data) - new Date(a.cmtr_data)}))
      setComments(key)
    } else {}
    // setLoading(false);
  }


  const AddNewComments = async (idFase) => {
    // setLoading(true)
    let res = await Api.addComment({
      "cmtr_fase_id": idFase,
      "cmtr_usuario_id": user.id,
      "cmtr_descricao": addComments,
      "cmtr_data": moment().format("YYYY-MM-DD HH:mm:ss"),
    });

    setAddComments('')
    setShowAddComments(false)
    setComments('')
    // setLoading(false);
  }


  const GetAtividades = async (idFase) => {
    let res = await Api.getCtrlActivity(idFase);
    if (res) {
      setAtividades(res)
    } else {}
     setActsFull(true);
  }

  
  const UpgAtividades = async (postData) => {
    let res = await Api.upgCtrlActivity(postData);
    setActs('') 
    getCardProcess()
  }


  const DelProcessPhase = async (idFase, data) => {
    let res = await Api.delProcessPhase(idFase);
    if (res === 1) {
      let res = await Api.upgProcess(data)
    } else {}
    CloseModalClick()
  }


  const DelProcesso = async (idProcesso) => {
    let res = await Api.delProcess(idProcesso);
    setDelProcessSelected('')
    CloseModalClick()
  }


  const UpgPhasePay = async (postData) => {
    let res = await Api.upgProcessPhase(postData);
    getCardProcess()
    setValuePay('')
  }


  const UpgPhase = async (postData, proc_id, estado, etapa, honorario) => {
    let res1 = await Api.upgProcessPhase(postData);
    let res2 = await Api.upgProcess({
      "proc_estado_id": estado,
      "proc_etapa": etapa,
      "proc_id": proc_id,
      "proc_honorario_valor": honorario
    });
    getCardProcess()
  }


  const onSubmit = async (data, type) => {
    if (processNumber === null || processNumber === '') {
      setStateErros(true)
    }
    else {
      setStateErros(false)
      data["proc_numero"] = processNumber    
      let res = await Api.upgProcess(data)
      type === 1 ? setAddProcessPhase(true) : CloseModalClick() 
    }
  }



/// DETECTA AS TECLAS ESCAPE (ESC) E ENTER 
  // document.onkeydown = function (event) {
  //   switch (event.code) {
  //     case 'Escape':
  //       CloseModalClick();
  //       break;
  //     case 'Enter':
  //       if(modal === true) {
  //         handleSubmit(onSubmit)();
  //       }
  //       break;
  //     default:
  //       break;
  //   }
  // }


  // document.addEventListener('keydown', (event) => {
  // })

  // document.addEventListener('keydown', (event) => {
  //   switch (event.code) {
  //     case 'Escape':
  //       //CloseModalClick();
  //       break;
  //     case 'Enter':
  //       //handleSubmit(onSubmit)();
  //       break;
  //     // default:
  //     //   break;
  //   }
  // })



/// USEEFECT //////////////////////////

  useEffect(() => {
    reset();
  }, [modal]);

  useEffect(()=>{
    getCardProcess()
  }, [ state, prevPay, switchButton, actsPainel ]);


/// CONSOLE ///////////////////////////

  // console.log("delPhaseSelected: ", delPhaseSelected)
  // console.log("user.adm: ", user.adm)
  console.log("fasesProcessoList", fasesProcessoList)
  




  return (
    <C.Container>

      <C.Header>
        <C.TitleArea>
          <TaskIcon style={{ fontSize: '32px', margin: '0px 10px 0px 0px' }}/>
          <h2>Processos</h2>
        </C.TitleArea>

        <C.SearchArea>
          {!switchButton && !prevPay &&
            <G.StateArea>
              <TaskIcon onClick={() => RefreshClick('1')} style={{ fontSize: state === '1' ? '36px' : '30px', outline: 'none', color: '#F15068', cursor: 'pointer' }}/>
              <TaskIcon onClick={() => RefreshClick('2')} style={{ fontSize: state === '2' ? '36px' : '30px', outline: 'none', color: '#51B476', cursor: 'pointer' }}/>
              <TaskIcon onClick={() => RefreshClick('3')} style={{ fontSize: state === '3' ? '36px' : '30px', outline: 'none', color: '#84b6f4', cursor: 'pointer' }}/>
              {state !== '' &&
                <CloseIcon onClick={() => RefreshClick('')} style={{ cursor: 'pointer'}}/>
              }
            </G.StateArea> 
          }
          {!prevPay &&
            <G.SwitchArea aria-label="Exibe todos os Processos criados.">
              <BusinessIcon style={{ paddingBottom: '2px', borderBottom: switchButton ? 'none' : '3px solid #7137C8', color: switchButton ? '#7C7484' : '#7137C8' }}/>
              <G.CircleButton onClick={() => setSwitchButton(switchButton === false ? true : false) + setState('')}>
                <PublishedWithChangesIcon 
                  fontSize="small" 
                  style={{ padding: '2px', borderRadius: '50%' }} 
                />
              </G.CircleButton>
              <AccountCircleIcon  
                style={{ paddingBottom: '2px', borderBottom: switchButton ? '3px solid #7137C8' : 'none', color: switchButton ? '#7137C8' : '#7C7484' }}
              />
            </G.SwitchArea>
          }
          <G.SearchIconArea>
            <SearchIcon />
          </G.SearchIconArea>

          {/* disabled={state !== '' ? true : false} */}

          <G.SearchInput id='search' onChange={(event) => handleSearch(event)}></G.SearchInput>

          {user.adm === 1 && 
            <>
              <G.SwitchArea style={{ minWidth: '164px', width: 'auto', justifyContent: 'flex-end', marginRight: '0px', marginLeft: '12px', padding: '0px 4px 0px 8px' }}>
                {!prevPay &&
                  <G.CircleButton 
                    style={{ marginRight: '5px' }} 
                    onClick={() =>  RefreshClick('') + setSwitchButton(false) + setActsPainel(true) }>
                    <TaskAltOutlinedIcon style={{ borderRadius: '50%' }}/>
                  </G.CircleButton> 
                }
                {(prevPay ) && 
                  <G.Button onClick={() => PayClick()} style={{ width: '100px', marginRight: '10px', backgroundColor: '#2E8B57' }}>
                    Faturamento
                    <OpenInNewIcon fontSize="small" style={{ marginLeft: '7px' }} />
                  </G.Button>
                }
                <G.CircleButton 
                  style={{ marginRight: '5px', backgroundColor: prevPay ? '#BDF5D6' : '#f7f7f7' }} 
                  onClick={() => setState('') + setSwitchButton(false) + setPrevPay(prevPay === false ? true : false)}
                >
                  <AttachMoneyIcon style={{ borderRadius: '50%' }}/>
                </G.CircleButton> 
                <G.Button onClick={GoClick} style={{ width: '100px', backgroundColor: '#333' }}>
                  Arquivados
                  <OpenInNewIcon fontSize="small" style={{ marginLeft: '7px'}} />
                </G.Button>
                
              </G.SwitchArea>
            </>
          }
        </C.SearchArea>

      </C.Header>


      <C.Body>

        {loading &&  
          <C.Logo src={"/loading.svg"} />
        }

        {empty &&
          <G.OptionsArea style={{justifyContent: 'center', display: 'flex', marginTop: '120px', flexDirection: 'column'}}>
          <ErrorOutlineRoundedIcon style={{fontSize: '80px', color: '#aaa' }}/>
          <G.MainTitle style={{fontSize: '16px', borderBottom: '1px solid #aaa', paddingBottom: '7px', marginTop: '10px', color: '#aaa' }}>
             Não existem itens para o status selecionado 
          </G.MainTitle>
          </G.OptionsArea>
        }


        {actsPainel &&
        
        <M.ModalContainer>
          <M.ModalContentLarge style={{backgroundColor: '#f0f0f0', width: '1100px'}} >
            <G.BoxHeader style={{backgroundColor: '#f0f0f0'}}>
              <G.HeaderTitleArea style={{backgroundColor: '#fff', padding: '5px 20px', borderRadius: '10px' }}>Painel | Processos com atividades ativas</G.HeaderTitleArea>
              <G.BoxHeaderOptionsArea onClick={() => setActsPainel(false)} style={{backgroundColor: '#fff', borderRadius: '50%', cursor: 'pointer' }}><CloseIcon/></G.BoxHeaderOptionsArea>
            </G.BoxHeader>

            {loading &&  
              <C.Logo src={"/loading.svg"} />
            }

            {empty &&
              <G.OptionsArea style={{ justifyContent: 'center', display: 'flex', marginTop: '120px', flexDirection: 'column' }}>
              <ErrorOutlineRoundedIcon style={{fontSize: '80px', color: '#aaa' }}/>
              <G.MainTitle style={{ fontSize: '16px', borderBottom: '1px solid #aaa', paddingBottom: '7px', marginTop: '10px', color: '#aaa' }}>
                Não existem itens para o status selecionado 
              </G.MainTitle>
              </G.OptionsArea>
            }

            <G.BoxScroll style={{ maxHeight: 'none', height: '500px', width: '98%', border: 'none', backgroundColor: '#f0f0f0' }}>

              {filteredList.map((item, key)=>(
                <G.BoxBody key={key} style={{ justifyContent: 'center', flexDirection: 'column', alignItems: 'center', width: '100%', margin: '5px' }}>
                  <G.OptionsArea style={{ margin: '-20px 0px -7px 0px' }}> 
                  <TaskIcon style={{ backgroundColor: '#fff', padding: '7px', border: '1px solid #ccc', borderRadius: '50%', zIndex: '5', fontSize: '30px', color: item.proc_tipo === 3 ? '#84b6f4' : item.proc_tipo === 2 ? '#51B476' : item.proc_tipo === 1 ? '#F15068' : '#333' }}/>

                    <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', borderRadius: '20px', padding:'7px', width: '350px', marginLeft: '-25px', flexDirection: 'row', display: 'flex', justifyContent: 'start' }}>
                      <G.InfoArea style={{ backgroundColor: '#f1f1f1', padding: '3px 7px', marginLeft: '25px' }}>
                        <G.Text style={{ fontWeight: 'bold' }}>{item.proc_id}</G.Text>
                      </G.InfoArea>

                      <G.InfoArea>
                        <G.Text>{item.proc_identificacao}</G.Text>
                      </G.InfoArea>
                    </div>
                    
                    <AccountTreeIcon style={{ marginLeft: '-15px', backgroundColor: '#fff', padding: '7px', border: '1px solid #ccc', borderRadius: '50%', zIndex: '5', fontSize: '26px', color: '#333' }}/>

                    <div style={{backgroundColor: '#fff', marginLeft: '-20px', color: '#000', border: '1px solid #ccc', borderRadius: '20px', padding:'10px', width: '250px', flexDirection: 'row', display: 'flex', justifyContent: 'center'}}>
                      <G.InfoArea> <G.Text>{item.fase_descricao}</G.Text></G.InfoArea>
                    </div>
                  </G.OptionsArea>
                  
                  {filteredList[key].atividades.map((acts, key)=>( 
                    <G.OptionsArea key={key} style={{ flexDirection: 'row', backgroundColor: '#fff', borderRadius: '30px', width: '95%', margin: '5px', boxShadow: '1px 2px 1px #ccc', padding: '0px' }}>
                      {(acts.usr_foto !== null || acts.usr_foto !== '')
                        ? <G.Image 
                            src={`${process.env.REACT_APP_BACKEND}/download?file=${acts.usr_foto}`} 
                            style={{ width: '52px', height: '52px', borderRadius: '50%', border: '3px solid #ccc', marginRight: '10px' }}
                          />
                        : <G.Image 
                            src={'/person.svg'} 
                            style={{ height: '52px', borderRadius: '50%', border: '2px solid #ccc', minWidth: '52px', marginRight: '10px' }}
                          />
                      }
                      <G.InfoArea style={{ marginRight: '20px', marginLeft: '-7px', backgroundColor: '#f9f9f9', borderRadius: '5px', padding:'7px', width: '150px', justifyContent: 'start' }}>
                        <G.Text style={{ fontWeight: 'bold' }}>{acts.usr_nome}</G.Text>
                      </G.InfoArea>
                      <G.InfoArea style={{ marginRight: '20px', backgroundColor: '#f9f9f9', borderRadius: '0px', padding:'7px', width: '150px', justifyContent: 'start' }}>
                        <TaskAltOutlinedIcon style={{marginRight: '10px'}}/>
                        <G.Text>{acts.ctrl_atvd_nome}</G.Text>
                      </G.InfoArea>
                      <G.InfoArea style={{ marginRight: '20px', color: '#fff', backgroundColor: acts.ctrl_atvd_estado === 'Executando' ? 'green' : '#ffb400', borderRadius: '5px', padding:'7px', width: '100px', justifyContent: 'center' }}>
                        <G.Text>{acts.ctrl_atvd_estado}</G.Text>
                      </G.InfoArea>
                      <G.InfoArea style={{ marginRight: '20px', backgroundColor: '#f9f9f9', borderRadius: '0px', padding:'7px', width: '160px', justifyContent: 'start' }}>
                        <DateRangeIcon style={{ marginRight: '10px' }}/>
                        <G.Text>{new Date(acts.ctrl_atvd_inicio).toLocaleString()}</G.Text>
                      </G.InfoArea>
                      <G.InfoArea style={{ backgroundColor: '#f9f9f9', borderRadius: '0px', padding:'7px', width: '100px', justifyContent: 'start' }}>
                        <AccessTimeIcon style={{ marginRight: '10px' }}/>
                        <G.Text>
                          {acts.ctrl_atvd_estado === 'Executando' && 
                            <div>
                              {
                                parseInt(moment.duration(moment().diff(acts.ctrl_atvd_durante ? acts.ctrl_atvd_durante : acts.ctrl_atvd_inicio) + acts.ctrl_atvd_duracao).asHours()).toString().padStart(2, '0') + 'h' + 
                                parseInt(moment.duration(moment().diff(acts.ctrl_atvd_durante ? acts.ctrl_atvd_durante : acts.ctrl_atvd_inicio) + acts.ctrl_atvd_duracao).asMinutes()% 60).toString().padStart(2, '0') + 'm'
                              }
                            </div>
                          }
                          {(acts.ctrl_atvd_estado === 'Pendente' ) && 
                            <div>
                              {
                                parseInt(moment.duration(acts.ctrl_atvd_duracao).asHours()).toString().padStart(2, '0') + 'h' + 
                                parseInt(moment.duration(acts.ctrl_atvd_duracao).asMinutes() % 60).toString().padStart(2, '0') + 'm'
                              }
                            </div>
                          }
                        </G.Text>
                      </G.InfoArea>
                    </G.OptionsArea>
                  ))}

                </G.BoxBody>
              ))}

           </G.BoxScroll>

          </M.ModalContentLarge>
        </M.ModalContainer>
        }

        {/* CARD DE PROCESSO */}
        {(!loading && filteredList.length > 0)  &&
          filteredList.map((item, key)=>(
            <C.Card key={key}>

              <C.CardHeader 
                style={{ 
                  //borderBottom: moment().diff(item.fase_proc_data_pazo, 'days') >= -1 ? '2px solid red' : '2px solid green', 
                  borderBottom: item.proc_tipo === 3 ? '2px solid #84b6f4' : item.proc_tipo === 2 ? '2px solid #51B476' : item.proc_tipo === 1 ? '2px solid #F15068' : '2px solid #333' 
                }}
              >
                <C.CardHeaderLogo>
                  {/* 
                    TIPOS DE PROCESSO: 
                      1 - Perícia     (Vermelho)  (#F15068) 
                      2 - Reclamante  (Verde)     (#51B476) 
                      3 - Reclamada   (Azul)      (#84b6f4) 
                  */}
                  <TaskIcon fontSize="large" style={{ color: item.proc_tipo === 3 ? '#84b6f4' : item.proc_tipo === 2 ? '#51B476' : item.proc_tipo === 1 ? '#F15068' : '#333' }}/>
                </C.CardHeaderLogo>
                <C.CardHeaderInfo style={{ height: '24px', justifyContent: 'flex-end' }}>
                  {/* <C.CardHeaderNumber>{item.proc_id.toString().padStart(6)}{' ｜ '}{item.proc_codigo}{' - '}{item.proc_vara_junta}</C.CardHeaderNumber> */}
                  <C.CardHeaderNumber>{item.proc_id.toString().padStart(6)}{' ｜ '}{item.proc_identificacao}</C.CardHeaderNumber>
                </C.CardHeaderInfo>
                <C.CardHeaderLogo>
                  {/* BOTÃO PARA DELETAR PROCESSO */}
                  {(item.total_fase === 1 && user.adm === 1) && 
                    <DeleteIcon onClick={() => setDelProcessSelected(key)} style={{ fontSize: '20px', color: '#fff', cursor: 'pointer',  backgroundColor: '#777', padding: '3px', borderRadius: '5px'}}/>
                  }
                  {/* BOTÃO PARA ACESSO AOS DETALHES DO PROCESSO */}
                  <OpenInNewIcon onClick={() => ProcessDetails(key, item.proc_id, item.proc_tipo)} style={{  fontSize: '32px', color: '#777', cursor: 'pointer' }}/>
                </C.CardHeaderLogo>
                {/* TELA DELETAR PROCESSO */}
                <div style={{ maxWidth: '1px', maxHeight: '1px' }}>
                  {delProcessSelected === key &&
                    <div style={{maxWidth: '1px', maxHeight: '1px'}}>
                      <G.PopUpAreaFlex id={key} style={{marginLeft: '-350px', marginTop: '0px'}}>
                        <G.PopUpBody>
                          <G.Text style={{ marginBottom: '5px' }}>Deseja excluir o processo selecionado ?</G.Text>
                        </G.PopUpBody>
                        <G.PopUpBody>
                          <G.Button style={{ backgroundColor: '#E95839' }} onClick={() => setDelProcessSelected('')}>
                            Não
                          </G.Button>
                          <G.Button style={{ backgroundColor: '#2E8B57' }} onClick={() => DelProcesso(item.proc_id)}>
                            Sim
                          </G.Button>
                        </G.PopUpBody> 
                      </G.PopUpAreaFlex> 
                    </div>
                  }
                </div>
              </C.CardHeader>

              <C.CardBody>
                <C.CardBodyA style={{ justifyContent: 'flex-start' }}>

                  <C.CardBodyText style={{ fontSize: '13px', borderLeft: '5px solid #2E8B57', backgroundColor: '#F5F5F5', padding: '7px 12px' }}>
                    <strong>
                      <C.CardHeaderTitle style={{ fontSize: '13px' }}>
                        {item.proc_reclamante.length > 30 ? item.proc_reclamante.substring(0,30)+' ...' : item.proc_reclamante}
                      </C.CardHeaderTitle>
                    </strong>
                  </C.CardBodyText>
                  <C.CardBodyText style={{ fontSize: '13px', borderLeft: '5px solid #1E90FF', backgroundColor: '#F5F5F5', pardding: '7px 12px' }}>
                    <strong>
                      <C.CardHeaderTitle style={{ fontSize: '13px' }}>
                        {item.proc_reclamada.length > 30 ? item.proc_reclamada.substring(0,30)+' ...' : item.proc_reclamada}
                      </C.CardHeaderTitle>
                    </strong>
                  </C.CardBodyText >

                  <C.CardBodyText style={{ padding: '5px 10px', display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                    {item.proc_tipo === 1 // Processo Perícia
                      ? <AccountBoxIcon style={{ marginRight: '5px', fontSize: '26px' }}/>
                      : <BusinessCenterIcon style={{ marginRight: '5px', fontSize: '26px' }}/>
                    }
                    {item.proc_tipo === 1 // Processo Perícia
                      ? (item.proc_perito_nome) 
                      : (item.adv_nome)
                    }
                  </C.CardBodyText>
                  
                  <C.CardBodyText style={{ padding: '5px 10px', display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                    <CalculateIcon style={{ marginRight: '5px', fontSize: '26px' }}/>
                    {item.proc_calculista_nome}
                  </C.CardBodyText>
                  {item.proc_tipo === 3 && // Processo Reclamada
                    <C.CardBodyText style={{ padding: '5px 10px', display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                      <BusinessIcon style={{ marginRight: '5px', fontSize: '26px' }}/>
                        {item.emprs_nome.length > 30 ? item.emprs_nome.substring(0,30)+' ...' : item.emprs_nome}
                    </C.CardBodyText>
                  }
                </C.CardBodyA>

                <C.CardBodyB style={{ display: 'flex', alignItems: 'center' }}>
            
                  <C.CardBodyStatusText  style={{ display: 'flex', flexDirection: 'column', maxWidth: '90%',  height: '100%', alignItems: 'center', justifyContent: 'start' }}>
                    {!prevPay && !switchButton &&
                      <C.ActsBox  
                        onClick={() => setActsDetails(key) } 
                        style={{ display: 'flex', padding: '5px 0px ', width: '170px', backgroundColor: '#F7f7f7', justifyContent: 'start', alignItems: 'center', borderRadius: '10px', cursor: 'pointer' }}>
                        <G.DistacInfoText style={{ color: '#333', marginRight: '5px', fontSize: '12px' }}>
                          <TaskAltOutlinedIcon 
                            style={{ 
                              fontSize: '32px', 
                              margin: '0px 7px', 
                              color: filteredList[key].atividades.filter(qitem => qitem.ctrl_atvd_estado === 'Concluida' || qitem.ctrl_atvd_estado === 'N/A').length === filteredList[key].atividades.length
                                ? '#2E8B57' 
                                : '#ffb400' 
                            }} 
                          />
                            Atividades {filteredList[key].atividades.filter(qitem => qitem.ctrl_atvd_estado === 'Concluida' || qitem.ctrl_atvd_estado === 'N/A').length}/{filteredList[key].atividades.length}
                        </G.DistacInfoText>
                        <ArrowOutwardOutlinedIcon  style={{color: '#333', fontSize: '16px' }} />
                      </C.ActsBox>
                    }
                    <G.OptionsArea style={{ maxHeight: '0px'}}>
                      {acts === key &&
                        <G.PopUpArea id={key}>
                            <G.PopUpCloseArea onClick={() => setActs('')}>
                              <CloseIcon style={{ fontSize: '20px' }}/>
                            </G.PopUpCloseArea>
                            <G.DistacInfoText style={{ backgroundColor: '#f9f9f9', padding: '7px', borderRadius: '7px 7px 0px 0px' }}>
                            {(selectedActs.usr_foto !== null || selectedActs.usr_foto !== '')
                              ? <G.Image 
                                  src={`${process.env.REACT_APP_BACKEND}/download?file=${selectedActs.usr_foto}`} 
                                  style={{ width: '52px', height: '52px', borderRadius: '50%', border: '3px solid #ccc', marginRight: '10px' }}
                                />
                              : <G.Image 
                                  src={'/person.svg'} 
                                  style={{ width: '52px', height: '52px', borderRadius: '50%', border: '3px solid #ccc', marginRight: '10px' }}
                                />
                            }
                            <G.DistacInfoText style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'space-around' }}>
                              <G.DistacInfoText>{selectedActs.usr_nome}</G.DistacInfoText>
                                <G.InfoText style={{ color: '#777', fontSize: '12px' }}>
                                  {selectedActs.ctrl_atvd_nome}
                                </G.InfoText>
                              </G.DistacInfoText> 
                            </G.DistacInfoText> 
                            <G.DistacInfoText style={{ backgroundColor: '#e5e5e5', padding: '7px', borderRadius: '0px 0px 7px 7px', justifyContent: 'space-between' }}>
                                          
                              {user.id === selectedActs.ctrl_atvd_usuario_id &&
                                <>
                                  {selectedActs.ctrl_atvd_estado === 'Executando' &&
                                    <PauseCircleIcon 
                                      style={{cursor: 'pointer', fontSize: '26px', color: 'gray'}} 
                                      onClick={() => UpgAtividades({
                                        "ctrl_atvd_id": selectedActs.ctrl_atvd_id,
                                        "ctrl_atvd_usuario_id": user.id,
                                        "ctrl_atvd_estado": 'Pendente',
                                        "ctrl_atvd_duracao": moment.duration(moment().diff(selectedActs.ctrl_atvd_durante ? selectedActs.ctrl_atvd_durante : selectedActs.ctrl_atvd_inicio) + selectedActs.ctrl_atvd_duracao).asMilliseconds()
                                      })}
                                    />
                                  }
                              
                                  {(selectedActs.ctrl_atvd_estado === 'Pendente' || selectedActs.ctrl_atvd_estado === 'Reaberta') &&
                                    <NotStartedIcon 
                                      style={{cursor: 'pointer', fontSize: '26px', color: 'gray'}} 
                                      onClick={() => UpgAtividades({
                                        "ctrl_atvd_id": selectedActs.ctrl_atvd_id,
                                        "ctrl_atvd_usuario_id": user.id,
                                        "ctrl_atvd_estado": 'Executando',
                                        "ctrl_atvd_durante": moment().format("YYYY-MM-DD HH:mm:ss")
                                      })}
                                    />
                                  }

                                  {(selectedActs.ctrl_atvd_estado === 'Executando' || selectedActs.ctrl_atvd_estado === 'Pendente') &&
                                    <StopCircleIcon 
                                      style={{cursor: 'pointer', fontSize: '26px', color: 'gray'}} 
                                      onClick={() => UpgAtividades({
                                        "ctrl_atvd_id": selectedActs.ctrl_atvd_id,
                                        "ctrl_atvd_usuario_id": user.id,
                                        "ctrl_atvd_estado": 'Concluida',
                                        "ctrl_atvd_duracao": moment.duration(moment().diff(selectedActs.ctrl_atvd_durante ? selectedActs.ctrl_atvd_durante : selectedActs.ctrl_atvd_inicio) + selectedActs.ctrl_atvd_duracao).asMilliseconds(),
                                        "ctrl_atvd_fim": moment().format("YYYY-MM-DD HH:mm:ss")
                                      })}
                                    />
                                  }
                                </> 
                              }

                              <G.InfoText 
                                style={{ marginRight: '10px', color: '#fff', backgroundColor: selectedActs.ctrl_atvd_estado === 'Executando' ? '#2E8B57' : '#FCC703', padding: '5px 10px', borderRadius: '5px'}}>
                                {selectedActs.ctrl_atvd_estado}
                              </G.InfoText>

                              <G.InfoText style={{ width: '60px', marginLeft: '10px', backgroundColor: '#333', padding: '5px', justifyContent: 'center', color: '#fff', borderRadius: '5px'}}>
                                {selectedActs.ctrl_atvd_estado === 'Executando' && 
                                  <div>
                                    {
                                      parseInt(moment.duration(moment().diff(selectedActs.ctrl_atvd_durante ? selectedActs.ctrl_atvd_durante : selectedActs.ctrl_atvd_inicio) + selectedActs.ctrl_atvd_duracao).asHours()) + 'h' + 
                                      parseInt(moment.duration(moment().diff(selectedActs.ctrl_atvd_durante ? selectedActs.ctrl_atvd_durante : selectedActs.ctrl_atvd_inicio) + selectedActs.ctrl_atvd_duracao).asMinutes()% 60) + 'm'
                                    }
                                  </div>
                                }
                                {(selectedActs.ctrl_atvd_estado === 'Concluida' || selectedActs.ctrl_atvd_estado === 'Pendente' || selectedActs.ctrl_atvd_estado === 'Reaberta') && 
                                  <div>
                                    {
                                      parseInt(moment.duration(selectedActs.ctrl_atvd_duracao).asHours()) + 'h' + 
                                      parseInt(moment.duration(selectedActs.ctrl_atvd_duracao).asMinutes() % 60) + 'm'
                                    }
                                  </div>
                                }
                              </G.InfoText>

                            </G.DistacInfoText> 
                        </G.PopUpArea>
                      }

                      {actsDetails === key &&
                        <G.PopUpArea style={{width: '500px'}} id={key}>
                          <G.PopUpCloseArea onClick={() => setActsDetails('')+setPrevValue('R$ 0,00')}>
                            <CloseIcon style={{ fontSize: '20px' }}/>
                          </G.PopUpCloseArea>

                          <G.DistacInfoText style={{ backgroundColor: '#e5e5e5', padding: '8px', borderRadius: '4px' }}>
                            <TaskAltOutlinedIcon style={{ fontSize: '24px',  marginRight: '5px' }}/>
                            Resumo das Atividades
                          </G.DistacInfoText> 
                          {filteredList[key].atividades.sort((a, b) => a.ctrl_atvd_id - b.ctrl_atvd_id).map((acts , index) => (
                            <G.DistacInfoText style={{ backgroundColor: '#fff', padding: '8px 0px',  borderBottom: '1px solid #e0e0e0', justifyContent: 'space-around' }}>
                            <G.InfoText style={{ width: '150px', flexDirection: 'column', alignItems: 'start' }}>
                              <div>{acts.ctrl_atvd_nome}</div><div style={{ color: 'gray' }}>{acts.usr_nome}</div>
                            </G.InfoText>
                            <G.InfoText style={{ width: '70px', flexDirection: 'column', alignItems: 'center' }}>
                              <div 
                                style={{ 
                                  display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: '70px', padding: '4px 5px', borderRadius: '4px', color: '#fff', 
                                  backgroundColor: 
                                      acts.ctrl_atvd_estado === 'Executando' ? 'green' 
                                    : acts.ctrl_atvd_estado === 'Concluida' ? '#333' 
                                    : acts.ctrl_atvd_estado === 'Pendente' ? 'orange' 
                                    : acts.ctrl_atvd_estado === 'N/A' ? 'gray' 
                                    : 'blue',
                                }}
                              >
                                {acts.ctrl_atvd_estado}
                              </div> 
                            {acts.ctrl_atvd_estado === 'Executando' && 
                              <div>
                                {
                                  parseInt(moment.duration(moment().diff(acts.ctrl_atvd_durante ? acts.ctrl_atvd_durante : acts.ctrl_atvd_inicio) + acts.ctrl_atvd_duracao).asHours()).toString().padStart(2, '0') + 'h' + 
                                  parseInt(moment.duration(moment().diff(acts.ctrl_atvd_durante ? acts.ctrl_atvd_durante : acts.ctrl_atvd_inicio) + acts.ctrl_atvd_duracao).asMinutes()% 60).toString().padStart(2, '0') + 'm'
                                }
                              </div>
                            }
                            {(acts.ctrl_atvd_estado === 'Concluida' || acts.ctrl_atvd_estado === 'Pendente' || acts.ctrl_atvd_estado === 'Reaberta') && 
                              <div>
                                {
                                  parseInt(moment.duration(acts.ctrl_atvd_duracao).asHours()).toString().padStart(2, '0') + 'h' + 
                                  parseInt(moment.duration(acts.ctrl_atvd_duracao).asMinutes() % 60).toString().padStart(2, '0') + 'm'
                                }
                              </div>
                            }

                            </G.InfoText>
                            <G.InfoText style={{ width: '60px', textAlign: 'right', color: '#555', fontSize: '11px', borderBottom: acts.ctrl_atvd_inicio ? '2px solid green' : null, padding: '3px' }}>
                              {acts.ctrl_atvd_inicio && new Date(acts.ctrl_atvd_inicio).toLocaleString()}
                            </G.InfoText>

                            <G.InfoText style={{ width: '60px', textAlign: 'right', color: '#555', fontSize: '11px', borderBottom: acts.ctrl_atvd_fim ? '2px solid red' : null, padding: '3px' }}>
                              {acts.ctrl_atvd_fim && new Date(acts.ctrl_atvd_fim).toLocaleString()}
                            </G.InfoText>
                            
                            <G.InfoArea style={{width: '60px', justifyContent: 'end', padding: '0px'}}>
                              {(acts.ctrl_atvd_estado === 'Aberta' || acts.ctrl_atvd_estado === 'Reaberta') &&
                                <PlayCircleIcon 
                                  style={{ cursor: 'pointer', fontSize: '26px', color: 'gray' }} 
                                  onClick={() => UpgAtividades({
                                    "ctrl_atvd_id": acts.ctrl_atvd_id,
                                    "ctrl_atvd_usuario_id": user.id,
                                    "ctrl_atvd_estado": 'Executando',
                                    "ctrl_atvd_inicio": moment().format("YYYY-MM-DD HH:mm:ss")
                                  })}
                                />
                              }

                              {user.adm === 1 && 
                                <>
                                  {acts.ctrl_atvd_estado !== 'Aberta' &&
                                    <ReplayCircleFilledIcon 
                                      style={{ cursor: 'pointer', fontSize: '26px', color: 'gray' }} 
                                      onClick={() => UpgAtividades({
                                        "ctrl_atvd_id": acts.ctrl_atvd_id,
                                        "ctrl_atvd_usuario_id": null,
                                        "ctrl_atvd_estado": 'Aberta',
                                        "ctrl_atvd_inicio": null,
                                        "ctrl_atvd_duracao": 0,
                                        "ctrl_atvd_durante": null,
                                        "ctrl_atvd_fim": null
                                      })}
                                    />
                                  }

                                  {acts.ctrl_atvd_estado === 'Aberta' &&
                                    <CancelIcon 
                                      style={{ cursor: 'pointer', fontSize: '26px', color: 'gray' }} 
                                      onClick={() => UpgAtividades({
                                        "ctrl_atvd_id": acts.ctrl_atvd_id,
                                        "ctrl_atvd_usuario_id": null,
                                        "ctrl_atvd_estado": 'N/A',
                                        "ctrl_atvd_inicio": null,
                                        "ctrl_atvd_duracao": 0,
                                        "ctrl_atvd_durante": null,
                                        "ctrl_atvd_fim": null
                                      })}
                                    />
                                  }

                                  {acts.ctrl_atvd_estado === 'Concluida' &&
                                    <ChangeCircleIcon 
                                      style={{ cursor: 'pointer', fontSize: '26px', color: 'gray' }} 
                                      onClick={() => UpgAtividades({
                                        "ctrl_atvd_id": acts.ctrl_atvd_id,
                                        "ctrl_atvd_usuario_id": null,
                                        "ctrl_atvd_estado": 'Reaberta',
                                        "ctrl_atvd_fim": null
                                      })}
                                    />
                                  }
                                </>
                              }

                            </G.InfoArea>
                      
                          </G.DistacInfoText> 
                          ))}

                          <G.InfoArea style={{ justifyContent: (filteredList[key].proc_homologado_valor !== '0.00' || filteredList[key].proc_honorario_valor !== '0.00') ? 'space-between' : 'end', marginTop: '12px', padding: '0px' }}>
                          
                            {(filteredList[key].proc_homologado_valor !== '0.00' || filteredList[key].proc_honorario_valor !== '0.00') && 
                              <G.InfoArea style={{ width: '260px', justifyContent: 'space-around', padding: '6px', borderRadius: '4px', backgroundColor: '#FFEEAD' }}>
                                  <WarningRoundedIcon  style={{ color: '#ffb400'  }}/>
                                <G.InfoText>
                                  O Processo possui valor {filteredList[key].proc_homologado_valor !== '0.00' ? ' Homologado' : filteredList[key].proc_honorario_valor !== '0.00' ? ' de Honorário' : ''}
                                </G.InfoText>
                              </G.InfoArea>
                            }
                            <G.InfoArea style={{ width: '200px', justifyContent: 'space-around', padding: '8px', borderRadius: '4px', backgroundColor: '#f5f5f5' }}>
                              {/* <G.InfoText style={{ marginRight: '3px' }}>Total da fase atual:  </G.InfoText> */}
                              <G.InfoText>Duração Total da Fase:  </G.InfoText>
                              <G.DistacInfoText>
                                {parseInt(moment.duration(filteredList[key].atividades.reduce((a, b) => a + parseFloat(b.ctrl_atvd_duracao), 0)).asHours()).toString().padStart(2, '0') + 'h' 
                                + parseInt(moment.duration(filteredList[key].atividades.reduce((a, b) => a + parseFloat(b.ctrl_atvd_duracao), 0)).asMinutes() % 60).toString().padStart(2, '0') + 'm'} 
                              </G.DistacInfoText> 
                            </G.InfoArea>

                          </G.InfoArea>

                          {user.adm === 1 && // Usuário com função ADM
                            <>
                              {filteredList[key].atividades.filter(qitem => qitem.ctrl_atvd_estado === 'Concluida' || qitem.ctrl_atvd_estado === 'N/A').length === filteredList[key].atividades.length &&
                                <G.InfoArea style={{ justifyContent: 'space-between', marginTop: '10px' }}>

                                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <G.FormInputArea style={{ width: '64%' }}>
                                      <G.FormInputLabel 
                                        style={{ 
                                          borderLeft: filteredList[key].proc_homologado_valor !== '0.00' ? '3px solid orange' : filteredList[key].proc_honorario_valor !== '0.00' ? '3px solid orange' : '3px solid gray' 
                                        }}
                                      >
                                        {filteredList[key].proc_tipo === 3 ? 'Valor Estimado' : filteredList[key].proc_homologado_valor !== '0.00' ? 'Homologado' : 'Honorário'}
                                      </G.FormInputLabel>
                                      <G.FormInput 
                                        autoFocus 
                                        readOnly={filteredList[key].proc_homologado_valor !== '0.00' ? true : filteredList[key].proc_honorario_valor !== '0.00' ? true : false}
                                        defaultValue={filteredList[key].proc_homologado_valor !== '0.00' ? filteredList[key].proc_homologado_valor : filteredList[key].proc_honorario_valor !== '0.00' ? filteredList[key].proc_honorario_valor : 'R$ 0,00'} 
                                        //value={prevValue} 
                                        style={{ backgroundColor: filteredList[key].proc_homologado_valor !== '0.00' ? '#FFEEAD' : filteredList[key].proc_honorario_valor !== '0.00' ? '#FFEEAD' : '#F5F5F5' }}
                                        onChange={e => setPrevValue(e.target.value)} 
                                      />        
                                    </G.FormInputArea>
                                  </div>

                                  <G.InfoArea>
                                    <G.Button 
                                      style={{ backgroundColor: '#2E8B57' }} 
                                      onClick={() => UpgPhase(
                                          {
                                            fase_proc_id: filteredList[key].ctrl_atvd_fase_id, 
                                            fase_proc_estado: filteredList[key].proc_tipo === 3 ? 1 : 6, 
                                            fase_proc_data_saida: moment().format("YYYY-MM-DD"),
                                            fase_proc_valor_recebido: filteredList[key].proc_tipo === 3 ? prevValue.replace('.', "").replace(',', '.').replace(/[^0-9.-]+/g,"") : 0,
                                          }, 
                                          filteredList[key].proc_id, 
                                          filteredList[key].proc_tipo === 3 ? 1 : 2,
                                          filteredList[key].proc_tipo === 3 ? 2 : 6, filteredList[key].proc_tipo !== 3 ? prevValue.replace('.', "").replace(',', '.').replace(/[^0-9.-]+/g,"") : 0
                                        ) + setActsDetails('') + setPrevValue('R$ 0,00')
                                      }
                                    >
                                      Faturamento
                                    </G.Button>
                                  </G.InfoArea>

                                </G.InfoArea>
                              }
                            </>
                          }
                        </G.PopUpArea>
                      }
                      
                      {/* COMENTÁRIOS */}
                      {comments === key && comentarios &&
                        <G.PopUpArea style={{ width: '460px' }} id={key}>
                          <G.PopUpCloseArea onClick={() => setComments('') + setShowAddComments(false)}><CloseIcon style={{ fontSize: '20px' }}/></G.PopUpCloseArea>
                          <G.InfoArea style={{ display: 'flex', backgroundColor: '#555', padding: '7px', borderRadius: '7px 7px 0px 0px', color: '#fff', justifyContent: 'space-between' }}>
                            <G.DistacInfoText>
                            <CommentOutlinedIcon style={{ fontSize: '24px',  marginRight: '5px', color: '#fff' }}/>
                              Comentários
                            </G.DistacInfoText>
                            <AddCircleIcon onClick={() => document.getElementById('view')?.scrollIntoView({ behavior: 'smooth', block: 'nearest' }) + setShowAddComments(true) }/>
                          </G.InfoArea> 
                          <G.PopUpBodyScroll style={{height: '200px'}}>
                            <div id={'view'}></div>
                            {showAddComments &&
                              <G.InfoArea   style={{backgroundColor: '#fff', padding: '10px 5px', borderRadius: '5px', flexDirection: 'row', alignItems: 'center', marginTop: '10px'}}>
                                <G.TextArea value={addComments} onChange={e=>setAddComments(e.target.value)} type="textarea" style={{width: '100%', color: '#333', fontSize: '12px', height: '100px', marginRight: '-20px'}}/>
                                <PlayCircleIcon onClick={() => AddNewComments(item.ctrl_atvd_fase_id)} style={{fontSize: '36px', color: 'gray', backgroundColor: '#fff', borderRadius: '20px', marginRight: '-10px'}}/>
                              </G.InfoArea> 
                            }
                            {comentarios.map((comm, index) => (
                              <G.InfoArea  key={index} style={{backgroundColor: '#f7f7f7', padding: '15px 5px', borderRadius: '0px 15px 15px 15px', flexDirection: 'column', alignItems: 'start', marginTop: '10px'}}>
                                <G.InfoArea >
                                  { comm.usr_foto !== null
                                    ? <G.Image src={`${process.env.REACT_APP_BACKEND}/download?file=${comm.usr_foto}`} style={{ height: '26px', borderRadius: '18px', border: '3px solid #ccc', minWidth: '26px', marginRight: '10px' }}/>
                                    : <G.Image src={'/person.svg'} style={{ height: '26px', borderRadius: '18px', border: '3px solid #ccc', minWidth: '26px', marginRight: '10px' }}/>
                                  }
                                  <G.DistacInfoText style={{ width: '240px' }}>{comm.usr_nome}</G.DistacInfoText>
                                  <G.DistacInfoText style={{ fontSize: '11px', color: '#999' }}>{new Date(comm.cmtr_data).toLocaleString()} </G.DistacInfoText>
                                </G.InfoArea>
                                <G.InfoArea>
                                  <G.InfoText style={{ marginTop: '5px', fontSize: '12px' }}>{comm.cmtr_descricao}</G.InfoText>                           
                                </G.InfoArea>
                              </G.InfoArea> 
                            ))}
                          </G.PopUpBodyScroll>
                        </G.PopUpArea>
                      }   
                    </G.OptionsArea>
                        
                    {filteredList[key].atividades.filter(qitem => qitem.ctrl_atvd_estado === 'Executando' || qitem.ctrl_atvd_estado === 'Pendente').map((field , index) => (
                      <C.ActsBox key={index} onClick={() => setActs(key) + setSelectedActs(field)} style={{ display: 'flex',  alignItems: 'center', justifyContent: 'start', fontSize: '11px'}}>
                        {field.usr_foto !== null
                          ? <G.Image  src={`${process.env.REACT_APP_BACKEND}/download?file=${field.usr_foto}`} style={{ height: '36px', borderRadius: '50%', border: '3px solid #ccc', maxWidth: '34px' }} />
                          : <G.Image src={'/person.svg'} style={{ height: '36px', borderRadius: '50%', border: '3px solid #ccc', minWidth: '34px' }} />
                        }
                        {field.ctrl_atvd_estado === 'Executando' 
                          ? <PlayCircleIcon style={{marginBottom: '-25px', marginLeft: '-10px', marginRight: '3px', fontSize: '20px', color: 'green'}}/>
                          : <PauseCircleIcon style={{marginBottom: '-25px', marginLeft: '-10px', marginRight: '3px', fontSize: '20px', color: 'orange'}}/>
                        }
                        <div style={{marginLeft: '0px', padding: '7px 7px', border: '1px solid #ddd', borderRadius: '7px', width: '110px', alignItems: 'center'}}>
                          {field.ctrl_atvd_nome}
                        </div>
                      </C.ActsBox>
                    ))}
                      </C.CardBodyStatusText>
                            
                </C.CardBodyB>
              </C.CardBody>
              
              <C.CardFooter style={{ alignItems: 'center' }}>
                <C.CardHeaderInfo style={{ padding: '4px', fontSize: '12px',  marginLeft: '10px', borderRadius: '4px' }}>
                  <C.CardHeaderNumber style={{ fontWeight: 'normal', color: '#555', marginBottom: '4px', borderBottom: '1px dotted #777', width: '98%', padding: '2px 0px' }}>Fase Atual: </C.CardHeaderNumber>
                  <C.CardHeaderNumber style={{ color: '#444' }}>{item.fase_descricao}</C.CardHeaderNumber>
                </C.CardHeaderInfo>
                  <C.CardBodyStatusText style={{ display: 'flex', width: '80px', border: '1px solid green', padding: '4px 6px', color: '#000', justifyContent: 'space-between', flexDirection: 'column' }} >
                    <strong style={{ marginBottom: '2px' }}>Entrada</strong>{new Date(item.fase_proc_data_entrada).toLocaleDateString('pt-BR')}
                  </C.CardBodyStatusText>
                  <C.CardBodyStatusText style={{ display: 'flex', width: '80px', border: moment().diff(item.fase_proc_data_pazo, 'days') >= 0 ? '1px solid red' : '1px solid orange ', padding: '4px 6px', color: '#000', justifyContent: 'space-between', flexDirection: 'column' }}>
                    <strong style={{ marginBottom: '2px' }}>{item.proc_etapa === 1 ? 'Prazo' : 'Saída'}</strong> {new Date(item.proc_etapa === 1 ? item.fase_proc_data_pazo : item.fase_proc_data_saida).toLocaleDateString('pt-BR')}
                  </C.CardBodyStatusText>
                  <C.CardBodyStatusText 
                  
                    onClick={() => GetComentarios(key, item.ctrl_atvd_fase_id)} 
                    style={{ cursor: 'pointer', display: 'flex', maxWidth: '80px', backgroundColor: '#333', padding: '10px 8px', color: '#fff', justifyContent: 'center', flexDirection: 'column' }}
                  >
                    Comentários
                  </C.CardBodyStatusText>
                  {item.comentarios > 0 &&
                  <div style={{width: '2px', height: '2px'}}>
                     <G.badge style={{backgroundColor: '#f5f5f5', color: '#000'}}>{item.comentarios} </G.badge>
                  </div>
                  }
   
              </C.CardFooter>
            </C.Card>
          ))
        }
      </C.Body>

      <G.Footer style={{ color: '#333', fontSize: '12px' }}>
        {/* <p> Copyright <CopyrightIcon style={{ fontSize: '12px' }}/> {new Date().getFullYear()} – Joy Soluções e Tecnologia Ltda. </p> */}
      </G.Footer>


      {loadingModal &&  
        <C.ModalContainer>
          <C.Logo src={"/loading.svg"} />
        </C.ModalContainer>
      }

      {(modal !== false ) &&
        <M.ModalContainer>
          <M.ModalContentLarge style={{ justifyContent: 'center'}}>

            <M.ModalHeader 
              style={{ 
                height:'28px', 
                justifyContent: 'space-evenly'
              }}
            >
              <TaskIcon fontSize="large"
                style={{ 
                  color: filteredList[selectedProcess].proc_tipo === 1 ? '#F15068' 
                       : filteredList[selectedProcess].proc_tipo === 2 ? '#51B476' 
                       : filteredList[selectedProcess].proc_tipo === 3 ? '#84b6f4' 
                       : '#333' 
                  }}
              />
              <G.InfoText style={{ width: '96%', height: '28px', alignItems: 'flex-end',fontSize: '18px', color: '#222' }}>
                {'Detalhes do Processo'}
                {' | '}
                {filteredList[selectedProcess].proc_tipo === 1 ? 'Perícia' : filteredList[selectedProcess].proc_tipo === 2 ? 'Reclamante' : filteredList[selectedProcess].proc_tipo === 3 ? 'Reclamada' : 'Outro'}
              </G.InfoText>
            </M.ModalHeader>

            <M.ModalHeader style={{ height:'0px', border: 'none' }}>

              {actsFull && // Pop-up das Atividades da Fase
                <G.PopUpArea  style={{ marginLeft: '0px', marginTop: '300px', width: '700px' }}>
                  <G.PopUpCloseArea onClick={() => setActsFull('')}>
                    <CloseIcon style={{ fontSize: '20px' }}/>
                  </G.PopUpCloseArea>
                  <G.DistacInfoText style={{ padding: '7px', borderRadius: '6px 6px 6px 6px', backgroundColor: '#e5e5e5', marginBottom: '8px' }}>
                    <TaskAltOutlinedIcon style={{ fontSize: '24px',  marginRight: '5px' }}/>
                    Atividades
                  </G.DistacInfoText>
                  {atividades.map((item , index) => (
                    <G.TableArea key={index} style={{ justifyContent: 'space-around' }}>
                    <G.FieldArea size={'30%'}>
                      <G.InfoText>{item.ctrl_atvd_nome}</G.InfoText>
                    </G.FieldArea>
                    <G.FieldArea size={'24%'} style={{ backgroundColor: '#ebebeb' }}>
                      <G.InfoText style={{ padding: '0px 4px' }}>{item.usr_nome}</G.InfoText>
                    </G.FieldArea>
                    <G.FieldArea size={'10%'} style={{ color: '#fff', backgroundColor: '#2E8B57', justifyContent:'center' }}>
                      {item.ctrl_atvd_inicio === null 
                        ? <G.InfoText>--/--/----</G.InfoText> 
                        : <G.InfoText>{new Date(item.ctrl_atvd_inicio).toLocaleDateString()}</G.InfoText>
                      }
                      {/* <G.InfoText>{new Date(item.ctrl_atvd_inicio).toLocaleDateString('pt-BR')}</G.InfoText> */}
                    </G.FieldArea>
                    <G.FieldArea size={'10%'} style={{ color: '#fff',backgroundColor: '#E95839', justifyContent:'center' }}>
                      {item.ctrl_atvd_fim === null 
                        ? <G.InfoText>--/--/----</G.InfoText> 
                        : <G.InfoText>{new Date(item.ctrl_atvd_fim).toLocaleDateString()}</G.InfoText>
                      }
                      {/* <G.InfoText>{new Date(item.ctrl_atvd_fim).toLocaleDateString('pt-BR')}</G.InfoText> */}
                    </G.FieldArea>
                    <G.FieldArea size={'10%'} style={{color: '#fff',backgroundColor: '#555', justifyContent:'center'}}>
                      <G.InfoText>
                        {
                          parseInt(moment.duration(item.ctrl_atvd_duracao).asHours()).toString().padStart(2, '0') + 'h' + 
                          parseInt(moment.duration(item.ctrl_atvd_duracao).asMinutes() % 60).toString().padStart(2, '0') + 'm'
                        }
                      </G.InfoText>
                    </G.FieldArea>
                      </G.TableArea>
                    ))
                  }

                  <G.InfoArea style={{ width: '170px', height: '28px', padding: '6px', marginTop: '8px', backgroundColor: '#f5f5f5' }}>
                    <G.InfoText style={{ marginRight: '3px' }}>Total da fase:  </G.InfoText>
                    <G.DistacInfoText style={{marginRight: '10px'}}>
                    {parseInt(moment.duration(atividades.reduce((a, b) => a + parseFloat(b.ctrl_atvd_duracao), 0)).asHours()).toString().padStart(2, '0') + 'h' 
                    + parseInt(moment.duration(atividades.reduce((a, b) => a + parseFloat(b.ctrl_atvd_duracao), 0)).asMinutes() % 60).toString().padStart(2, '0') + 'm'} 
                    </G.DistacInfoText> 
                  </G.InfoArea>
                </G.PopUpArea>
              }

              {addProcessPhase && // Adicinar nova Fase a um Processo Ativo
                <G.PopUpArea  style={{ width: '700px', height: '432px', alignItems: 'center', marginLeft: '0px', marginTop: '400px' }}>

                  <G.PopUpCloseArea onClick={() => setAddProcessPhase('') + setActivityPhase('') + setFaseEntrada('') + setFasePrazo('')}>
                    <CloseIcon style={{ fontSize: '20px' }}/>
                  </G.PopUpCloseArea>

                  <G.SmallTitle style={{ width: '100%', padding: '10px', backgroundColor: '#f5f5f5' }}>
                      Adicionar Fase
                  </G.SmallTitle>
                  <G.BoxBody style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'start', color: '#fff' }}>

                    <G.FormInputArea style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

                      <div style={{ display: 'flex', flexDirection: 'row', width: '98%', justifyContent: 'space-between', margin: '4px 2px 8px 8px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '40%' }}>
                          <G.FormInputLabel style={{ borderLeft: '3px solid green', borderRadius: '4px 0px 0px 4px' }}>
                            Entrada
                          </G.FormInputLabel>
                          <G.FormInput type="date" onChange={(e) => setFaseEntrada(e.target.value)}/> 
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '40%' }}>
                          <G.FormInputLabel style={{ borderLeft: '3px solid orange', borderRadius: '4px 0px 0px 4px' }}>
                            Prazo
                          </G.FormInputLabel>
                          <G.FormInput type="date" min={faseEntrada} onChange={(e) => setFasePrazo(e.target.value)}/>
                        </div>
                      </div>
                      <div style={{ width: '96%', justifyContent: 'space-around', margin: '4px' }}>
                        <G.FormSelect style={{ borderRadius: '4px' }} onChange={e => GetActivityPhase(e.target.value)} onMouseOver={() => GetSelect(7)}>
                          <option>Selecione a Fase a adicionar</option>
                          {phase.map(p => (
                            <option key={p.fase_id} value={p.fase_id}>{p.fase_descricao}</option>
                          ))}
                        </G.FormSelect>
                      </div>

                    </G.FormInputArea>

                  </G.BoxBody>

                  <G.InfoArea style={{ width: '520px', padding: '10px', marginBottom: '16px', borderRadius: '0px', borderBottom: '1px solid #ccc', fontSize: '14px', color: '#000' }}>
                    Atividades
                  </G.InfoArea>
                  {(activityPhase === '' || activityPhase.length === 0) &&
                    <G.InfoArea style={{ justifyContent: 'space-around', padding: '6px', borderRadius: '4px', backgroundColor: '#FFEEAD' }}>
                      <WarningRoundedIcon  style={{ color: '#ffb400'  }}/>
                      <G.InfoText>
                        Fase com nenhuma atividade cadastrada
                      </G.InfoText>
                    </G.InfoArea>
                  }
                    <>
                      <div style={{ display: 'flex', width: '100%', height: '300px', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '16px' }}> 
                        {activityPhase !== '' &&
                          <G.InfoArea style={{ width: '520px', flexDirection: 'row', justifyContent: 'space-aroud', flexWrap: 'wrap' }}> 
                          {activityPhase.map((item, key)=>(
                            <G.FormInputArea key={key} style={{ flexDirection: 'column', alignItems: 'start', marginRight: '20px' }}>
                              <G.FormInputLabel style={{ 
                                  width: '100%', 
                                  justifyContent: 'center', 
                                  borderRadius: '4px', 
                                  borderLeft: '0px solid black', 
                                  backgroundColor: '#777',
                                  color: '#FFF', 
                                  // fontWeight: 'normal'
                                }}
                              >
                                {item.ctrl_atvd_nome}
                              </G.FormInputLabel>
                            </G.FormInputArea>
                          ))}  
                          </G.InfoArea>
                        }
                      </div>
                    </>
                  <G.InfoArea>
                    <G.Button 
                      style={{ backgroundColor: (activityPhase.length === 0 || faseEntrada === '' || fasePrazo === '') ? '#BBB' : '#2E8B57' }} 
                      onClick={() => (activityPhase.length === 0 || faseEntrada === '' || fasePrazo === '') ? undefined : AddProcessPhase()}
                    >
                      Adicionar
                    </G.Button>
                  </G.InfoArea>
                </G.PopUpArea>
              }
              
            </M.ModalHeader>

            {/* DETALHES DO PROCESSO ATIVO */}
            {user.adm === 1 && // Usuário com função ADM
              <M.ModalBody style={{ width: '1000px', flexDirection: 'column', alignItems: 'center', marginTop: '5px' }}>
                <G.BoxBody style={{ margin: '0px 0px', flexWrap: 'wrap',  justifyContent: 'start' }}>

                  <G.FormInputArea style={{ width: '15%' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid gray' }}>Código</G.FormInputLabel>
                    <G.FormInput 
                      type='text' 
                      name='proc_id' 
                      readOnly={true}
                      defaultValue={filteredList[selectedProcess].proc_id.toString().padStart(6)} 
                      style={{ fontSize: '13px', color: '#111' }}
                      {...register("proc_id", { required: true })}
                    />
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '31.5%' }}> 
                    <G.FormInputLabel style={{ borderLeft: '3px solid purple'}}>Identificação</G.FormInputLabel>
                    <G.FormInput 
                      type='text' 
                      name='proc_identificacao' 
                      defaultValue={
                        filteredList[selectedProcess].proc_identificacao === null 
                        ? '' 
                        : filteredList[selectedProcess].proc_identificacao
                      } 
                      {...register("proc_identificacao", { required: true })}
                    />
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '26.5%'}}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid orange' }}>Processo</G.FormInputLabel>
                    <G.FormInput 
                      type='text' 
                      name='proc_codigo' 
                      defaultValue={
                        filteredList[selectedProcess].proc_codigo === null 
                        ? '' 
                        : filteredList[selectedProcess].proc_codigo
                      } 
                      {...register("proc_codigo", { required: true })}
                    />  
                  </G.FormInputArea>
                  
                  <G.FormInputArea  style={{ width: '20%' }}>
                    <G.FormInputLabel>Entrada</G.FormInputLabel>
                    <G.FormInput 
                      type="date" 
                      name='proc_data_entrada'
                      defaultValue={moment(filteredList[selectedProcess].proc_data_entrada).format("YYYY-MM-DD")} 
                      {...register("proc_data_entrada", { required: true }, { setValueAs: v => moment(v).format("YYYY-MM-DD") })}
                    /> 
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '48%' }}>
                    <G.FormInputLabel>Junta</G.FormInputLabel>
                    <G.FormInput 
                      type='text' 
                      name='proc_vara_junta'
                      defaultValue={
                        filteredList[selectedProcess].proc_vara_junta === null 
                        ? '' 
                        : filteredList[selectedProcess].proc_vara_junta
                      } 
                      {...register("proc_vara_junta", { required: true })}
                    /> 
                  </G.FormInputArea>

                  {/* <G.FormInputArea style={{ width: '20%' }}>
                    <G.FormInputLabel>Prazo: </G.FormInputLabel><G.FormInput defaultValue={moment(filteredList[selectedProcess].proc_data_prazo).format("YYYY-MM-DD")} type="date"
                    {...register("proc_data_prazo", {setValueAs: v => moment(v).format("YYYY-MM-DD")})}/>                      
                  </G.FormInputArea> */}

                  <G.FormInputArea style={{ width: '48%' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid navy' }}>
                      Núm. Proc.
                    </G.FormInputLabel>
                    <G.FormInput 
                      type='text'
                      name='processNumber'
                      placeholder='Código Processo TRT'
                      value={
                        processNumber === null 
                        ? '' 
                        : mask(processNumber, ['9999999-99.9999.9.99.9999'])
                      }
                      onChange={onChange}
                    />
                  </G.FormInputArea>

                  {/* <G.FormInputArea style={{ width: '20%' }}></G.FormInputArea> */}

                  <G.FormInputArea style={{ width: '48%' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #2E8B57' }}>Reclamante</G.FormInputLabel>
                    <G.FormInput 
                      type='text' 
                      name='proc_reclamante' 
                      defaultValue={
                        filteredList[selectedProcess].proc_reclamante === null 
                        ? '' 
                        : filteredList[selectedProcess].proc_reclamante
                      } 
                      {...register("proc_reclamante", { required: true })}
                    />                      
                  </G.FormInputArea>

                  {/* PROCESSO RECLAMANTE || PROCESSO RECLAMADA */}
                  {(filteredList[selectedProcess].proc_tipo === 2 || filteredList[selectedProcess].proc_tipo === 3 ) &&
                    <G.FormInputArea style={{ width: '48%' }}>
                      <G.FormInputLabel style={{ borderLeft: '3px solid gray' }}>Advogado</G.FormInputLabel>
                      <G.FormSelect 
                        type='text' 
                        name='advogado' 
                        defaultValue={
                          filteredList[selectedProcess].proc_advogado_id === null 
                          ? '' 
                          : filteredList[selectedProcess].proc_advogado_id
                        } 
                        {...register("proc_advogado_id", 
                          { required: (filteredList[selectedProcess].proc_tipo === 2 || filteredList[selectedProcess].proc_tipo === 3 ) ? true : false }, 
                          { setValueAs: v => (filteredList[selectedProcess].proc_tipo === 2 || filteredList[selectedProcess].proc_tipo === 3 ) ? v : 'N/A' }
                        )}
                      >
                        <option>{filteredList[selectedProcess].adv_nome}</option>
                          {lawyer.map(a => (
                            <option key={a.proc_advogado_id} value={a.proc_advogado_id}>{a.adv_nome}</option>
                          ))}
                      </G.FormSelect>                      
                    </G.FormInputArea>
                  }

                  {/* PROCESSO PERÍCIA */}
                  {filteredList[selectedProcess].proc_tipo === 1 && 
                    <>
                      <G.FormInputArea style={{ width: '48%' }}>
                        <G.FormInputLabel style={{ borderLeft: '3px solid #E95839' }}>Perito Responsável</G.FormInputLabel>
                        <G.FormInput 
                          type='text' 
                          name='proc_perito_nome'
                          defaultValue={
                            filteredList[selectedProcess].proc_perito_nome === null 
                            ? '' 
                            : filteredList[selectedProcess].proc_perito_nome
                          } 
                          {...register("proc_perito_nome", 
                            { required: filteredList[selectedProcess].proc_tipo === 1 ? true : false }, 
                            { setValueAs: v => filteredList[selectedProcess].proc_tipo === 1 ? v : 'N/A' }
                          )}
                        />                      
                      </G.FormInputArea>
                    </>
                  }

                  <G.FormInputArea style={{ width: '48%' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #1E90FF' }}>Reclamada</G.FormInputLabel>
                    <G.FormInput 
                      type='text' 
                      name='proc_reclamada' 
                      defaultValue={
                        filteredList[selectedProcess].proc_reclamada === null 
                        ? '' 
                        : filteredList[selectedProcess].proc_reclamada
                      } 
                      {...register("proc_reclamada", { required: true })}
                    />                      
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '48%' }}>
                    <G.FormInputLabel>Calculista</G.FormInputLabel>
                      <G.FormSelect 
                        type='text' 
                        name='proc_calculista_nome' 
                        defaultValue={
                          filteredList[selectedProcess].proc_calculista_nome === null 
                          ? '' 
                          : filteredList[selectedProcess].proc_calculista_nome
                        } 
                        {...register("proc_calculista_nome", { required: true })}
                      >
                      <option >{filteredList[selectedProcess].usr_id}</option>
                          {users.map(u => (
                            <option key={u.usr_id} value={u.usr_nome}>{u.usr_nome}</option>
                          ))}
                        </G.FormSelect>                     
                  </G.FormInputArea>

                  {/* PROCESSO RECLAMADA */}
                  {filteredList[selectedProcess].proc_tipo === 3 &&
                    <G.FormInputArea style={{ width: '48%' }}>
                      <G.FormInputLabel style={{ borderLeft: '3px solid gray' }}>Empresa</G.FormInputLabel>
                      <G.FormSelect 
                        type='text' 
                        name='empresa'                     
                        defaultValue={
                          filteredList[selectedProcess].proc_empresa_id === null 
                          ? '' 
                          : filteredList[selectedProcess].proc_empresa_id
                        } 
                        {...register("proc_empresa_id", 
                          { required: filteredList[selectedProcess].proc_tipo === 3 ? true : false }, 
                          { setValueAs: v => filteredList[selectedProcess].proc_tipo === 3 ? v : 'N/A' }
                        )}
                      >
                        <option >{filteredList[selectedProcess].emprs_nome}</option>
                        {company.map(a => (
                          <option key={a.emprs_id} value={a.emprs_id}>{a.emprs_nome}</option>
                        ))}
                      </G.FormSelect> 
                    </G.FormInputArea>
                  }

                  {/* PROCESSO PERÍCIA */}
                  {filteredList[selectedProcess].proc_tipo === 1 &&
                    <>
                      <G.FormInputArea style={{ width: '23%' }}>
                        <G.FormInputLabel>Honorário</G.FormInputLabel>
                        <G.FormInput 
                          type='text' 
                          name='proc_honorario_valor'                       
                          defaultValue={
                            filteredList[selectedProcess].proc_honorario_valor === null 
                            ? '0' 
                            : parseFloat(filteredList[selectedProcess].proc_honorario_valor).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})
                          } 
                          {...register("proc_honorario_valor", 
                            // { required: filteredList[selectedProcess].proc_tipo === 1 ? true : false }, 
                            // {setValueAs: v => filteredList[selectedProcess].proc_tipo === 1 ? Number(v.replace('.', "").replace(',', '.').replace(/[^0-9.-]+/g,"")) : 0}
                            {setValueAs: v => Number(v.replace('.', "").replace(',', '.').replace(/[^0-9.-]+/g,""))}
                          )}
                        />                  
                      </G.FormInputArea>

                      <G.FormInputArea style={{ width: '23%' }}>
                        <G.FormInputLabel>Homologado</G.FormInputLabel>
                        <G.FormInput 
                          type='text' 
                          name='proc_homologado_valor'                       
                          defaultValue={
                            filteredList[selectedProcess].proc_homologado_valor === null 
                            ? '0' 
                            : parseFloat(filteredList[selectedProcess].proc_homologado_valor).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})
                          } 
                          {...register("proc_homologado_valor", 
                            // { required: filteredList[selectedProcess].proc_tipo === 1 ? true : false }, 
                            // {setValueAs: v => filteredList[selectedProcess].proc_tipo === 1 ? Number(v.replace('.', "").replace(',', '.').replace(/[^0-9.-]+/g,"")) : 0}
                            {setValueAs: v => Number(v.replace('.', "").replace(',', '.').replace(/[^0-9.-]+/g,""))}
                          )}
                        />                      
                      </G.FormInputArea>
                    </>
                  }
                </G.BoxBody>

                <div>
                  {
                    (Object.keys(errors).length !== 0 || stateErrors) && 
                    <div role="alert" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px', borderRadius: '4px', fontSize: '12px', backgroundColor: '#FCAC9B', color: '#000' }}>
                      <WarningRoundedIcon  style={{ color: '#333', marginRight: '5px'  }}/>
                      {'PARA SALVAR TODOS OS CAMPOS DEVEM ESTAR PREENCHIDOS.'}
                    </div>
                  }
                </div>
              </M.ModalBody>
            }
            <M.ModalBody style={{ width: '1000px', flexDirection: 'column', alignItems: 'center', marginTop: '5px' }}>
              
              {/* DETALHES PROCESSO > TABELA - FASES DO PROCERSSO */}
              <G.Box style={{  flexDirection: 'column', width: filteredList[selectedProcess].proc_tipo === 3 ? '95%' : '70%', height: '180px', justifyContent: 'start', backgroundColor: '#ddd' }}>
                <G.TableHeader style={{ width: '100%', borderBottom: '2px solid #bbb', backgroundColor: '#ddd'}}>

                  <G.OptionsArea style={{ width: '100%', justifyContent: 'space-around', padding: '4px 1px' }}>
                    <G.FieldArea size={'16%'}>
                      <G.DistacInfoText>Fase</G.DistacInfoText>
                    </G.FieldArea>
                    <G.FieldArea size={'7%'}>
                      <G.DistacInfoText>Entrada</G.DistacInfoText>
                    </G.FieldArea>
                    <G.FieldArea size={'7%'}>
                      <G.DistacInfoText>Prazo</G.DistacInfoText>
                    </G.FieldArea>
                    <G.FieldArea size={'7%'}>
                      <G.DistacInfoText>Saída</G.DistacInfoText>
                    </G.FieldArea>
                    <G.FieldArea size={'15%'}>
                      <G.DistacInfoText>Estado</G.DistacInfoText>
                    </G.FieldArea>

                    {/* PROCESSO RECLAMADA */}
                    {filteredList[selectedProcess].proc_tipo === 3 &&
                      <>
                        <G.FieldArea size={'10%'}><G.DistacInfoText>Cobrança</G.DistacInfoText></G.FieldArea>
                        <G.FieldArea size={'10%'}><G.DistacInfoText>Valor</G.DistacInfoText></G.FieldArea>
                      </>
                    }

                    <G.FieldArea size={'8.5%'}>
                      <G.DistacInfoText>Ações</G.DistacInfoText>
                    </G.FieldArea>
                  </G.OptionsArea>
                </G.TableHeader>

                <G.TableBodyScroll>
                  {fasesProcessoList.map((item, key) => (
                    
                    <G.TableArea key={key} style={{ width: '100%', justifyContent: 'space-around', padding: '4px 1px' }}>

                      <G.FieldArea 
                        size={'16%'} 
                        style={{ 
                          borderRadius: '3px', 
                          borderLeft: item.fase_proc_estado === 1 ? '4px solid green'  // 1 – Estado – Aberto
                                    : item.fase_proc_estado === 2 ? '4px solid orange' // 2 – Estado – Faturamento
                                    : item.fase_proc_estado === 3 ? '4px solid blue'   // 3 – Estado – Faturado
                                    : item.fase_proc_estado === 4 ? '4px solid orange' // 4 – Estado – Pgmto Aberto
                                    : item.fase_proc_estado === 5 ? '4px solid gray'   // 5 – Estado – Quitado
                                    : item.fase_proc_estado === 6 ? '4px solid grey'   // 6 – Estado – Encerrado
                                    : '4px solid red'
                        }}
                      >
                        <G.InfoText style={{ marginLeft: '10px' }} >{item.fase_descricao}</G.InfoText>
                      </G.FieldArea>

                      <G.FieldArea size={'7%'}>
                        <G.InfoText>{new Date(item.fase_proc_data_entrada).toLocaleDateString()}</G.InfoText>
                      </G.FieldArea>

                      <G.FieldArea size={'7%'}>
                        {item.fase_proc_data_pazo === null 
                          ? <G.InfoText>--/--/----</G.InfoText> 
                          : <G.InfoText>{new Date(item.fase_proc_data_pazo).toLocaleDateString()}</G.InfoText>
                        }
                      </G.FieldArea>

                      <G.FieldArea size={'7%'}>
                        {item.fase_proc_data_saida === null 
                          ? <G.InfoText>--/--/----</G.InfoText> 
                          : <G.InfoText>{new Date(item.fase_proc_data_saida).toLocaleDateString()}</G.InfoText>
                        }
                      </G.FieldArea>

                      <G.FieldArea 
                        size={'15%'} 
                        style={{ 
                          justifyContent: 'flex-start', 
                          borderRadius: '0px', 
                          borderBottom: item.fase_proc_estado === 1 ? '4px solid green'  // 1 – Estado – Aberto
                                      : item.fase_proc_estado === 2 ? '4px solid orange' // 2 – Estado – Faturamento
                                      : item.fase_proc_estado === 3 ? '4px solid blue'   // 3 – Estado – Faturado
                                      : item.fase_proc_estado === 4 ? '4px solid orange' // 4 – Estado – Pgmto Aberto
                                      : item.fase_proc_estado === 5 ? '4px solid gray'   // 5 – Estado – Quitado
                                      : item.fase_proc_estado === 6 ? '4px solid grey'   // 6 – Estado – Encerrado
                                      : '4px solid red'
                        }}
                      >
                        <G.InfoText>{item.estd_descricao}</G.InfoText>
                      </G.FieldArea>

                      {/* PROCESSO RECLAMADA */}
                      {filteredList[selectedProcess].proc_tipo === 3 &&
                        <>
                          <G.FieldArea size={'10%'} style={{ justifyContent: 'center' }}><G.InfoText>{item.fase_proc_cobranca === 1 ? <DoneRoundedIcon /> : <ClearRoundedIcon />}</G.InfoText></G.FieldArea>
                          <G.FieldArea size={'10%'} style={{ justifyContent: 'flex-start' }}><G.InfoText>{parseFloat(item.fase_proc_valor_recebido).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'})}</G.InfoText></G.FieldArea>
                        </>
                      }
                      <G.FieldArea size={'8.5%'} style={{ justifyContent: 'end', padding: '1px' }}>
                        <G.InfoText>
                          {delPhaseSelected === key &&
                            <div style={{ maxWidth: '1px', maxHeight: '1px' }}>
                              <G.PopUpAreaFlex id={key} style={{marginLeft: '-300px', marginTop: '-20px'}}>

                                {/* <G.PopUpBody>
                                  <G.Text style={{ marginBottom: '8px' }}>Deseja excluir a fase?</G.Text>
                                </G.PopUpBody>
                                <G.PopUpBody>
                                  <G.Button style={{ backgroundColor: '#E95839' }} onClick={() => setDelPhaseSelected('')}>
                                    Não
                                  </G.Button>
                                  <G.Button style={{ backgroundColor: '#2E8B57' }} onClick={() => DelProcessPhase(item.fase_proc_id, {"proc_id": item.fase_proc_processo_id, "proc_estado_id": '2'}) + setDelPhaseSelected('')}>
                                    Sim
                                  </G.Button>
                                </G.PopUpBody> */}
                                
                                <G.PopUpBody style={{ justifyContent: 'space-between' }}>
                                  <G.Text style={{ marginRight: '16px' }}>Deseja excluir a fase?</G.Text>
                                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    {/* BOTÃO – DELETAR – NÃO */}
                                    <G.IconButton 
                                      fontSize="small" style={{ margin: '2px', backgroundColor: '#E95839' }}
                                      onClick={() => setDelPhaseSelected('')}
                                    >
                                      <ClearRoundedIcon/>
                                    {/* BOTÃO – DELETAR – SIM */}
                                    </G.IconButton>
                                    <G.IconButton 
                                      fontSize="small" style={{ margin: '2px', backgroundColor: '#2E8B57' }}
                                      onClick={() => DelProcessPhase(item.fase_proc_id, {"proc_id": item.fase_proc_processo_id, "proc_estado_id": '2'}) + setDelPhaseSelected('')}
                                    >
                                      <DoneRoundedIcon/>
                                    </G.IconButton>
                                  </div>
                                </G.PopUpBody>

                              </G.PopUpAreaFlex> 
                            </div>
                          }

                          {/* BOTÕES DE AÇÃO */}
                          <div style={{ display: 'flex', flexDirection: 'row', width: '100', padding: '2px' }}>
                            {/* DELETAR FASE */}
                            {(item.fase_proc_estado === 1 && user.adm === 1) && // 1 – Estado – Aberto && Usuário com função ADM
                              <G.IconButton style={{ backgroundColor: '#E95839', margin: '1px' }} fontSize="small" onClick={() => setDelPhaseSelected(key)}>
                                <DeleteIcon/>
                              </G.IconButton>
                            }
                            {/* DETALHES DA FASE – ATIVIDADES*/}
                            <G.IconButton style={{ backgroundColor: '#FFB400', margin: '1px' }} onClick={() => GetAtividades(item.fase_proc_id)}>
                              <AutoAwesomeMotionIcon fontSize="small"/>
                            </G.IconButton>
                          </div>
                        </G.InfoText>
                      </G.FieldArea>
                    </G.TableArea> 
                  ))}

                </G.TableBodyScroll>
              </G.Box>
            </M.ModalBody>
            <M.ModalFooter style={{ flexDirection: 'row', height: '24px', padding: '16px 8px 8px 8px' }}>
              

              {
                // fasesProcessoList.filter(qitem => qitem.proc_estado_id === 1).length === 0 &&
                // <G.Button style={{backgroundColor: '#1E90FF'}} onClick={() => setAddProcessPhase(true)}>Adicionar Fase</G.Button>
              } 
              {
                // fasesProcessoList.filter(qitem => qitem.fase_proc_estado === 1).length === 0 &&
                // <G.Button style={{ backgroundColor:  '#1E90FF' }} onClick={() => handleSubmit(onSubmit)(1)}>Adicionar Fase</G.Button>
              }

              {/* BOTÃO ADICIONAR FASE */}
              <G.Button style={{ backgroundColor: '#1E90FF' }} onClick={() => handleSubmit(onSubmit)(1)}>
                Adicionar Fase
              </G.Button>

              <G.Button style={{ backgroundColor: '#E95839' }} onClick={() => CloseModalClick()}>
                Fechar
              </G.Button>

              {user.adm === 1 &&
                <G.Button style={{ backgroundColor: '#2E8B57' }} onClick={() => handleSubmit(onSubmit)()}>
                  Salvar
                </G.Button>
              }
            </M.ModalFooter>

          </M.ModalContentLarge>
        </M.ModalContainer>
      }

      {payModal &&
        <M.ModalContainer>
          <M.ModalContentLarge>

            <M.ModalHeader>

              <G.InfoArea style={{ width: '300px', height: '30px', justifyContent: 'space-between', color: '#000' }}>
                <G.Input 
                  type='date' 
                  name='startDate'
                  value={startDate} 
                  onChange={e => setStartDate(e.target.value) + PayFilter(clientPay, e.target.value, endDate)}
                /> 
                <G.Input 
                  type='date' 
                  name='endDate'
                  value={endDate} 
                  disabled={startDate === undefined ? true : false} 
                  min={startDate} 
                  onChange={e => setEndDate(e.target.value) + PayFilter(clientPay, startDate, e.target.value)}
                />
              </G.InfoArea>

              <G.OptionsArea>
                <BusinessCenterIcon style={{ marginRight: '5px', fontSize: '26px' }}/>
                <G.FormSelect 
                  value={clientPay} 
                  onChange={e => setClientPay(e.target.value) + PayFilter(e.target.value, startDate, endDate)} 
                  style={{ width: '250px', borderRadius: '6px' }}
                >
                  <option value='' default selected>Selecione um Advogado</option>
                  {lawyer.map(a => (
                    <option key={a.proc_advogado_id} value={a.adv_nome}>{a.adv_nome}</option>
                  ))}
                </G.FormSelect>
              </G.OptionsArea>
              <G.OptionsArea>
                <BusinessIcon style={{marginRight: '5px', fontSize: '26px'}}/>
                <G.FormSelect 
                  value={clientPay} 
                  onChange={e => setClientPay( e.target.value) + PayFilter(e.target.value, startDate, endDate)} 
                  style={{ width: '250px', borderRadius: '6px' }}
                >
                  <option value='' default selected>Selecione uma Empresa</option>
                  {company.map(e => (
                    <option key={e.proc_empresa_id}  value={e.emprs_nome}>{e.emprs_nome}</option>
                  ))}
                </G.FormSelect>
              </G.OptionsArea>       
            </M.ModalHeader>
            <M.ModalBody style={{ width: '1000px', flexDirection: 'column', alignItems: 'center', marginTop: '5px' }}>
              <G.Box style={{ height: '400px', flexDirection: 'column', width: '95%', backgroundColor: '#ddd', justifyContent: 'start' }}>                
                <G.TableBodyScroll>
                  {filteredList.map((item, key) => (
                    <G.TableArea key={key} style={{ flexDirection: 'column', backgroundColor: item.fase_proc_cobranca === 0 ? '#FADBD8':'#d3d3d3', marginBottom: '10px', borderRadius: '10px' }}>

                      <G.OptionsArea style={{ justifyContent: 'space-between', width: '100%' }}>
                        <G.FieldArea size={'7%'}>
                          <G.InfoText style={{ marginLeft: '5px'}}>
                            <TaskIcon style={{ fontSize: '26px', marginRight: '5px', color: '#333' }}/>
                            {item.proc_id}
                          </G.InfoText>
                        </G.FieldArea>
                        <G.FieldArea size={'10%'} style={{ borderLeft: '3px solid green',  borderRadius: '3px' }}>
                          <G.InfoText style={{ arginLeft: '5px' }} >{item.proc_identificacao}</G.InfoText>
                        </G.FieldArea>
                        <G.FieldArea size={'30%'}>
                          <G.InfoText>{item.proc_reclamante}</G.InfoText>
                        </G.FieldArea>
                        <G.FieldArea size={'20%'}>
                          <G.InfoText>
                            <BusinessCenterIcon style={{ marginRight: '5px', fontSize: '26px' }}/>{item.adv_nome}</G.InfoText>
                          </G.FieldArea>
                        <G.FieldArea size={'20%'}>
                          <G.InfoText><BusinessIcon style={{ marginRight: '5px', fontSize: '26px' }}/>{item.emprs_nome}</G.InfoText>
                        </G.FieldArea>
                      </G.OptionsArea>

                      <G.OptionsArea style={{ padding: '0px', width: '100%', justifyContent: 'end', marginRight: '10px' }}>
                        <G.FieldArea size={'15%'} style={{ borderBottom: '3px solid orange', borderRadius: '0px', justifyContent: 'flex-start', marginRight: '10px' }}>
                          <G.InfoText>{item.fase_descricao}</G.InfoText>
                        </G.FieldArea>
                        <G.FieldArea size={'8%'}  style={{ border: '1px solid #333', justifyContent: 'center', marginRight: '10px' }}>
                          <G.InfoText>{new Date(item.fase_proc_data_saida).toLocaleDateString('pt-BR')}</G.InfoText>
                        </G.FieldArea>
                        <G.FieldArea size={'12%'} style={{ backgroundColor: '#333', color: '#fff', justifyContent: 'center', marginRight: '10px' }}>
                          <G.InfoText>{parseFloat(item.fase_proc_valor_recebido).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</G.InfoText>
                        </G.FieldArea>

                        <G.FieldArea>
                          <G.Input value={item.valuePay} style={{ textAlign: 'center', width: '100px' }} onChange={(e) => setValuePay(e.target.value)}/>
                        </G.FieldArea>
                        <PlayCircleIcon onClick={() => UpgPhasePay({fase_proc_id: item.ctrl_atvd_fase_id, fase_proc_valor_recebido: valuePay.replace('.', "").replace(',', '.').replace(/[^0-9.-]+/g,""), fase_proc_cobranca: 1 })}/>
                        <CancelIcon onClick={() => UpgPhasePay({fase_proc_id: item.ctrl_atvd_fase_id, fase_proc_valor_recebido: 0, fase_proc_cobranca: 0 })}/>
                      </G.OptionsArea>

                    </G.TableArea> 
                  ))}
                </G.TableBodyScroll>
              </G.Box>
            </M.ModalBody>
                <M.ModalFooter style={{ flexDirection: 'row', justifyContent: 'end', width: '95%' }}>
                <G.OptionsArea>
                  <G.Button style={{ backgroundColor: '#E95839' }} onClick={() => ClosePayModal()}>Fechar</G.Button>
                  <G.Button 
                    onClick={() => (startDate !== undefined && endDate !== undefined && clientPay !== '' && filteredList.length !== 0) ? setPayModalPdf(true) : null}
                    style={{ 
                      backgroundColor: (startDate !== undefined && endDate !== undefined && clientPay !== '' && filteredList.length !== 0 ) ? "#FFB400" : '#C6C6C6', 
                      cursor: (startDate !== undefined && endDate !== undefined && clientPay !== '' && filteredList.length !== 0) ? "pointer" : 'default' 
                    }} 
                  >
                    Gerar PDF
                  </G.Button>
                </G.OptionsArea> 
              </M.ModalFooter>
          </M.ModalContentLarge>
        </M.ModalContainer>
      }

      {payModalPdf &&
        <M.ModalContainer>
          <M.ModalContentLarge style={{ backgroundColor: '#fff' }}>
            <G.Box style={{ flexDirection: 'column', width: '1000px', justifyContent: 'start', overflowY: 'scroll', height: '400px' }}>
              <div ref={targetRef} style={{ width: '100%', backgroundColor: '#eee', padding: '50px' }}>
                <G.InfoText style={{ flexDirection: 'column', alignItems: 'start', marginBottom: '70px', fontSize: '14px' }}>
                  <p>Curitiba, {moment().format('DD MMMM YYYY')} </p>
                  <p>Cliente: <strong>{selectedPayEntity}</strong></p>

                  <p>Ref. Empresa : <strong>{filteredList[0].emprs_nome !== '' ? filteredList[0].emprs_nome : 'Nenhum Cliente selecionado'}</strong></p>

                  <p>Pagamento de Prestação de Serviços - Cálculos Trabalhistas</p>
                  Encaminhamos nota fiscal e relação de serviços prestados no periodo de {moment(startDate).format('DD-MM-YYYY')} a {moment(endDate).format('DD-MM-YYYY')} ,
                  referentes aos cálculos judiciais dos seguintes processos:
                  
                </G.InfoText> 

                {filteredList.map((item, key) => (
                  <G.TableArea  key={key} style={{ flexDirection: 'column', backgroundColor: item.fase_proc_cobranca === 0 ? '#FFF8F6':'#d3d3d3', marginBottom: '10px', borderRadius: '10px', padding: '10px' }}>
                    <G.OptionsArea style={{ justifyContent: 'space-between', width: '100%' }}>
                      <G.FieldArea size={'02%'}>
                        <G.InfoText style={{ marginLeft: '5px' }}>
                          <TaskIcon style={{ color: '#333', marginRight: '5px', fontSize: '26px' }}/>
                        </G.InfoText>
                      </G.FieldArea>
                      <G.FieldArea size={'20%'} style={{ borderLeft: '3px solid green',  borderRadius: '3px' }}>
                        <G.InfoText style={{ marginLeft: '5px' }}>
                          {item.proc_identificacao}
                        </G.InfoText>
                      </G.FieldArea>
                      <G.FieldArea size={'25%'} style={{ backgroundColor:'#FCC703', justifyContent: 'center'}}>
                        <G.InfoText>
                          {item.proc_reclamante}
                        </G.InfoText>
                      </G.FieldArea>
                      <G.FieldArea size={'20%'}>
                        <G.InfoText>
                          <AccountBoxIcon style={{ marginRight: '5px', fontSize: '26px' }}/>
                          {item.adv_nome}
                        </G.InfoText>
                      </G.FieldArea>
                      <G.FieldArea size={'20%'}>
                        <G.InfoText>
                          <BusinessIcon style={{ marginRight: '5px', fontSize: '26px' }}/>
                          {item.emprs_nome}
                        </G.InfoText>
                      </G.FieldArea>
                    </G.OptionsArea>
                    <G.OptionsArea style={{ padding: '0px', width: '100%', justifyContent: 'end', marginRight: '10px' }}>
                      <G.FieldArea size={'15%'} style={{ borderBottom: '3px solid orange', borderRadius: '0px', justifyContent: 'center', marginRight: '10px' }}>
                        <G.InfoText>
                          {item.fase}
                        </G.InfoText>
                      </G.FieldArea>
                      <G.FieldArea size={'08%'} style={{ border: '1px solid #333', justifyContent: 'center', marginRight: '10px' }}>
                        <G.InfoText>
                          {new Date(item.fase_proc_data_saida).toLocaleDateString('pt-BR')}
                        </G.InfoText>
                      </G.FieldArea>
                      <G.FieldArea size={'20%'} style={{ backgroundColor: '#333', color: '#FFF', justifyContent: 'center' }}>
                        <G.InfoText>
                          Valor: {parseFloat(item.fase_proc_valor_recebido).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                        </G.InfoText>
                      </G.FieldArea>
                    </G.OptionsArea>
                  </G.TableArea> 
                ))}

                <G.OptionsArea style={{ justifyContent: 'end' }}>
                  <G.InfoArea style={{ width: '200px', justifyContent: 'end', padding: '10px', marginRight: '15px', borderLeft: '4px solid #FCC703', borderRadius: '3px', backgroundColor: '#ddd', marginTop: '10px' }}>
                    <G.InfoText style={{ marginRight: '7px' }}>
                      Total:
                    </G.InfoText>
                    <G.DistacInfoText style={{ fontSize: '13px' }}>
                      {filteredList.reduce((a, b) => a + parseFloat(b.fase_proc_valor_recebido), 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                    </G.DistacInfoText>
                  </G.InfoArea>
                </G.OptionsArea> 

                <G.InfoText style={{ flexDirection: 'column', alignItems: 'start', marginTop: '70px', fontSize: '14px', backgroundColor: '#ddd', padding: '30px', borderRadius: '15px' }}>
                  <p>CALCULARE EMPREENDIMENTOS EMPRESARIAL E CÁLCULOS PERICIAIS LTDA.</p>
                  <p>Dados P/ depósito <br />
                  001 | Banco do Brasil S.A.<br />
                  Ag.: 1869-4 <br />
                  C/C: 118.000-2</p> 
                </G.InfoText>

                {/* <G.InfoText style={{ flexDirection: 'column', alignItems: 'start', marginTop: '70px', fontSize: '14px', backgroundColor: '#ddd', padding: '30px', borderRadius: '15px' }}>
                  <p>CALCULARE EMPREENDIMENTOS EMPRESARIAL E CÁLCULOS PERICIAIS LTDA</p>
                  <p>Dados para pagamento: <br />
                  000 | Nome do Banco <br />
                  Ag.: 0000-0 <br />
                  C/C: 0000000-0</p> 
                </G.InfoText>  */}

              </div>
            </G.Box>

            <M.ModalHeader style={{ backgroundColor: '#E8F6F3', width: '950px', borderRadius: '10px', height: '80px', flexDirection: 'column'  }}>
              <G.OptionsArea style={{ justifyContent: 'space-between' }}>
                <AccountBoxIcon style={{ marginRight: '5px', fontSize: '26px' }}/>
                <G.FormSelect 
                  isSearchable="true" 
                  value={selectedPayEntity} 
                  onChange={(e) => setSelectedPayEntinty(e.target.value)} 
                  style={{ width: '300px', backgroundColor: 'rgba(52, 52, 52, 0.1)', borderRadius: '15px', padding: '5px' }}
                > 
                  <option> Selecione </option>
                  <option> {filteredList.length > 0 ? filteredList[0].adv_nome : 'Não encontardo'}</option>
                  <option> {filteredList.length > 0 ? filteredList[0].emprs_nome : 'Não encontardo'}</option> 
                </G.FormSelect>
                <G.FormSelect onChange={(e) => setSelectedPayType(e.target.value)} style={{width: '200px', backgroundColor: 'rgba(52, 52, 52, 0.1)', borderRadius: '15px', padding: '5px'}}> 
                  <option> Selecione </option>
                  {payTypes.map(i => (<option key={i.frm_pgmt_id} value={[i.frm_pgmt_id]}>{i.frm_pgmt_descricao}</option>))}
                </G.FormSelect>
                <G.DistacInfoText style={{ marginRight: '10px' }}> Venc:</G.DistacInfoText>
                <G.Input type='date' onChange={(e) => setSelectedPayDate(e.target.value)}/>
                <G.DistacInfoText style={{ marginLeft: '10px', backgroundColor: 'rgba(52, 52, 52, 0.1)', padding: '10px', borderRadius: '15px' }}>
                  Total: {filteredList.reduce((a, b) => a + parseFloat(b.fase_proc_valor_recebido), 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                </G.DistacInfoText>
              </G.OptionsArea> 
            </M.ModalHeader>

            <M.ModalFooter style={{flexDirection: 'row'}}>
              <G.Button 
                onClick={() => setPayModalPdf(false)+setIsDownload(false)} 
                style={{ backgroundColor: '#E95839' }}
              >
                Fechar
              </G.Button>
              <G.Button 
                onClick={() => selectedPayEntity !== '' ? toPDF() + setIsDownload(true) : null}
                style={{backgroundColor: selectedPayEntity !== '' ? "#1E90FF" : '#C6C6C6', cursor: selectedPayEntity !== '' ? "pointer" : 'default'}} 
              >
                Download
              </G.Button>
              <G.Button 
                onClick={() => (isDownload && selectedPayDate && selectedPayType && selectedPayEntity) ? PayClickConfirm() : null}
                style={{
                  backgroundColor: (isDownload && selectedPayDate && selectedPayType && selectedPayEntity) ? "#2E8B57" : '#C6C6C6', 
                  cursor: (isDownload && selectedPayDate && selectedPayType && selectedPayEntity) ? "pointer" : 'default'
                }} 
              >
                Faturar
              </G.Button>
            </M.ModalFooter>

          </M.ModalContentLarge>
        </M.ModalContainer>
      }

      <Tooltip id="my-tooltip" place="bottom" />
    </C.Container>
  );
}