import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";

import * as C from './styled'
import Api from '../../services/api'
import { Alert } from '../../components/Alert'
import { ModalAlert } from '../../components/ModalAlert'

import LockOpenIcon from '@mui/icons-material/LockOpen';


export const Login = () => {

    const navigate = useNavigate();

    const [userField, setUserField] = useState('');
    const [passwordField, setPasswordField] = useState('');

    const [alert, setAlert] = useState('')
    const [alertColor, setAlertColor] = useState('')

    const [loading, setloading] = useState(true)

    const [modalAlert, setModalAlert] = useState('')
    const [modalAlertColor, setModalAlertColor] = useState('')

    const EnterClick = async () => {
       
            if (userField && passwordField) {

                if (passwordField === '321@mudar') {
                    FirstLoginClick()
                } else {
                    setloading(false)
                    let res = await Api.login(userField, passwordField)
                    if (res.token) {
                        localStorage.setItem('SessionID', res.token)
                        navigate('/Dashboard')
                    }
                    else {
                    setAlertColor('#E95839')
                    setAlert('Usuário ou senha inválidos !')
                    setloading(true)
                    }
                }
            } else {
                setAlertColor('#E95839')
                setAlert("Preencha todos os campos !")
                setloading(true)
            }
}

    const ProblemClick = () => {

        setModalAlert(`Caso necessite abrir um chamado com urgência, acesse <strong><a href="http://joyit.com.br/atendimento" 
            target="_blank"> joyit.com.br/atendimento </a> </strong> para ver outra opção de canal 
            e assim que possível envie um e-mail com o print do erro para <strong>esuporte@joyit.com.br</strong>`)
        setModalAlertColor('#FCC703')
    }

    const FirstLoginClick = () => {
        setModalAlert(`Verificamos que esse é seu primeiro login ou sua senha foi resetada <br><br>
                 <strong>Você será direcioanado em 10s automatimente para a troca da senha</strong>`)
        setModalAlertColor('#E95839')
        setTimeout(() => {
            navigate('/Renew' ,  {
                state: {
                  login: userField,
                }
              })
        }, 2000)
    }

    const SecurityClick = () => {
        setModalAlert(`Ao ativar essa opção, não será mais solicitada credenciais para acesso ao App. <br><br>
                 <strong>A Joy não se responsabiliza pelo uso indevido do App por pessoas não autorizadas.</strong>`)
        setModalAlertColor('#E95839')
    }

    const LostPasswordClick = () => {
        
        navigate('/Renew', {
            state: {
              login: userField,
            }
          }
          )
    }

    const CloseAlertClick = () => {
        setAlert('')
    }

    const ModalAlertClick = () => {
        setModalAlert('')
    }


    document.onkeydown = function (e) {
        if (e.key === 'Enter' || e.which === 13) {
            // alert("APERTOU ENTER!")
            EnterClick();
        }
    }



    

    return (
        <C.Container >
            <C.Logo src={"/logo.png"} />
            <C.InputArea >
                <C.Input placeholder={'Usuário'}
                    autoComplete="username"
                    value={userField}
                    onChange={e => setUserField(e.target.value)}
                    onFocus={CloseAlertClick}
                />
                <C.Input placeholder={'Senha'}
                    value={passwordField} onChange={e => setPasswordField(e.target.value)}
                    type="password"
                    autoComplete="current-password"
                    onFocus={CloseAlertClick}
                />
                <C.Button onClick={EnterClick}>{loading === true ? 'Entrar' : 'Acessando...'}</C.Button>
                {/* <C.ButtonClean onClick={SecurityClick}><LockOpenIcon />
                    Login automatico desativado
                </C.ButtonClean> */}
                <C.ButtonClean style={{ borderBottom: '0.1px solid #aaa' }} onClick={LostPasswordClick}>
                    Esqueceu a senha ?
                </C.ButtonClean>
                <C.ButtonClean style={{ borderBottom: '0.1px solid #aaa' }} onClick={ProblemClick}>
                    Não esta conseguindo acesso ?
                </C.ButtonClean>
                <C.Logo style={{ height: '30px', marginTop: '30px' }} src={"/logoFooter.png"} />
            </C.InputArea>

            <Alert textAlert={alert}
                setAlertClick={CloseAlertClick}
                colorAlert={alertColor} />

            {modalAlert !== '' &&
                <ModalAlert modalAlert={modalAlert}
                    setModalAlertClick={ModalAlertClick}
                    colorModalAlert={modalAlertColor} />
            }
        </C.Container>
    );
}
