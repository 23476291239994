import styled from 'styled-components';

export const Container = styled.div`
display: flex;
flex: 1;
justify-content: center;
align-items: center;
flex-direction: column;
height: 100vh;
background-color: #333;
color: #fff;
`;

export const Logo = styled.img`
witdh: auto;
height: 170px;

`;

export const InputArea = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
margin-top: 15px;
`;

export const Input = styled.input`
margin-top: 15px;
width: 330px;
border-radius: 15px;
border: none;
padding: 17px 20px;
font-size: 16px;
background-color: #EEE;
outline: 0;
color: #000;
`;

export const Button = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 370px;
height: 60px;
margin-top: 15px;
border-radius: 20px;
background-color: #7137c8;
border: none;
font-size: 14px;
color: #fff;
cursor: pointer;
&:hover {
  background-color: #1E90FF;
}
`;

export const ButtonClean = styled.div`
width: 330px;
height: 40px;
margin-top: 15px;
background-color: rgba(0, 0, 0, 0.01);
border: none;
font-size: 14px;
color: #fff;
cursor: pointer;
&:hover {
  font-weight: bold;
}
display: flex;
justify-content: center;
align-items: center;
`;

