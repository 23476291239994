import styled from 'styled-components';


/// GENERAL ////////////////////////////////////////////////////////////////////

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  width: 990px;
  background-color: #f9f9f9;
  border-radius: 5px;
  margin: 20px 5px 5px 5px;
  padding: 0px 15px;
  box-shadow: 0px 0px 3px #ccc;
`;

export const HeaderTitleArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  height: 40px;
  padding: 5px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 960px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin: 20px 5px 20px 5px;
  padding: 0px 15px;
`;


export const BoxScroll = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 960px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin: 20px 5px 20px 5px;
  padding: 0px 15px;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 22rem;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;  
    background-color: #777;
  }
`;


export const BoxHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 5px 5px 0px 0px;
  padding: 10px 10px;
`;

export const BoxHeaderTitleArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 5px;
`;

export const BoxHeaderOptionsArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 5px;
`;

export const BoxBody = styled.div`
  display: flex;
  margin: 10px 0px;
  padding: 10px;
`;

export const BoxBodyScroll = styled.div`
  width: 100%;
  padding: 5px;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 400px;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;  
    background-color: #777;
  }
`;

export const BoxFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #e0e0e0;
  margin-top: 5px;
  margin-bottom: 10px;
  width: 100%;
  padding: 20px 10px;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 990px;
  min-height: 30px;
  background-color: #fff;
  margin: 20px 5px 20px 5px;
  padding: 15px;
`;

export const OptionsArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 5px;
`;

export const InfoArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  padding: 0px 15px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
  min-height: 200px;
  background-color: #fff;
  border-radius: 5px;
  margin: 12px;
  box-shadow: 0px 0px 5px #ccc;
`;

export const CardHeader = styled.div`
  display: flex;
  height: 30px;
  justify-content: start;
  align-items: center;
  padding: 10px;
  border-radius: 6px 6px 0px 0px;
  background-color: #f0f0f0;
`;

export const CardBody = styled.div`
  display: flex;
  padding: 10px 10px;
`;

export const CardBodyScroll = styled.div`
  display: flex;
  padding: 10px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
      background-color: #f9f9f9;
  }
`;

export const CardFooter = styled.div`
  flex: 1;
  display: flex;
  height: 36px;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  border-radius: 0px 0px 6px 6px;
  margin-top: 4px;
  margin-bottom: 4px;
  background-color: #fff;
`;

export const Loading = styled.img`
  width: auto;
  margin-top: 100px;
`;

export const Loading2 = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 180px;
  margin: 60px;
`;

export const Image = styled.img`
  width: auto;
`;


/// DATA TABLES ////////////////////////////////////////////////////////////////

export const TableArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 1px;
  border-bottom: 1px solid #e0e0e0;
  /* cursor: pointer; */
  &:hover {
    background-color: #f5f5f5;
  }
`;

export const TableAreaHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  min-height: 30px;
  min-width: ${(props) => props.size};
  max-width: ${(props) => props.size};
  padding: 0px 5px;
  border-radius: 0px;
  &:hover {
    border-bottom: 1px dotted #333;
    transition: 0.5s ease-in-out;
  }
`;

export const FieldArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 40px;
  min-width: ${(props) => props.size};
  max-width: ${(props) => props.size};
  padding: 0px 4px;
  border-radius: 6px;
`;

export const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 32px;
  padding: 10px 10px;
  margin: 6px 0px 2px 0px;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 5px 5px 0px 0px;
  background-color: #e5e5e5;
`;

export const TableBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 2px 0px;
  padding: 10px 10px;
`;

export const TableBodyScroll = styled.div`
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 0px 0px;
  padding: 10px 10px;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 320px;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #999;
  }
`;

export const TableFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 20px;
  padding: 10px;
  border-top: 1px solid #e0e0e0;
`;


/// TEXTS //////////////////////////////////////////////////////////////////////

export const MainTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 22px;
  font-weight: bold;
`;

export const SubTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 18px;
  font-weight: bold;
`;

export const SmallTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 15px;
  font-weight: bold;
`;

export const DistacInfoText = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 13px;
  font-weight: bold;
`;

export const InfoText = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 11px;
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 12px;
`;


/// BUTTONS ////////////////////////////////////////////////////////////////////

export const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 20px;
  padding: 7px;
  margin: 0px 5px;
  color: #fff;
  border-radius: 6px;
  box-shadow: 0px 2px 2px #ccc;
  font-size: 12px;
  cursor: pointer;
  transition: transform 0.25s ease;
  &:hover {
    filter: contrast(140%);
    /* filter: brightness(1.1); */
    transition: 0.25s ease-in-out;
    font-weight: 480;
  }
  &:active {
    transform: scale(0.96);
  }
`;

export const ButtonOutLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 20px;
  padding: 7px;
  color: #fff;
  border-radius: 7px;
  font-size: 12px;
  cursor: pointer;
  margin: 0px 5px;
  &:hover {
    filter: brightness(1.1);
    font-weight: bold;
  }
`;

export const ButtonUnderLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  width: 160px;
  height: 20px;
  color: #333;
  border-bottom: 2px dotted #333;
  cursor: pointer;
  &:hover {
    border-bottom: 2px solid #333;
  }
`;

export const SpecialButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  width: 160px;
  height: 25px;
  border-bottom: 2px solid #2E8B57;
  color: #000;
  cursor: pointer;
  &:hover {
    border-bottom: 2px solid #333;
  }
`;

export const IconButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 20px;
  padding: 7px;
  color: #fff;
  font-size: 12px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 3px 3px #ccc;
  transition: transform 0.25s ease;
  &:hover {
    filter: contrast(140%);
    /* filter: brightness(1.1); */
    transition: 0.25s ease-in-out;
    font-weight: 480;
  }
  &:active {
    transform: scale(0.96);
  }
`;

export const DownloadButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 20px;
  padding: 7px;
  margin: 0px 5px;
  color: #fff;
  border-radius: 7px;
  box-shadow: 0px 2px 2px #ccc;
  font-size: 13px;
  cursor: pointer;
  transition: transform 0.25s ease;
  &:hover {
    filter: contrast(140%);
    /* filter: brightness(1.1); */
    transition: 0.25s ease-in-out;
    font-weight: 480;
  }
  &:active {
    transform: scale(0.96);
  }
`;

export const CircleButton = styled.div`
  padding: 5px;
  width: 20px;
  height: 20px;
  color: #000;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  background-color: #f7f7f7;
  &:hover {
    background-color: #EFFFF1;
  }
`;


export const BigButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  align-items: center;
  padding: 7px;
  width: 160px;
  height: 180px;
  border-radius: 10px;
  border: 1px solid #ffb400;
  cursor: pointer;
  margin: 30px;
  &:hover {
    background-color: #ffb400;
  }
`;

export const PlayIconButton = styled.div`
display: flex;
justify-content: center;
align-items: center;
padding: 2px;
width: 40px;
border: 1px solid #E5E5E5;
border-radius: 10px;
background-color: #F0F0F0;
color: #444;
cursor: pointer;
transition: transform 0.2s ease;
&:hover {
  transition: 0.25s ease-in-out;
  background-color: #7137C8;
  color: #F0F0F0;
}
&:active {
  transform: scale(0.94);
}
`;

export const FakeButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 20px;
  padding: 7px;
  margin: 0px 5px;
  color: #fff;
  border-radius: 7px;
  box-shadow: 0px 2px 2px #ccc;
  font-size: 13px;
  `;
 



/// OTHERS /////////////////////////////////////////////////////////////////////

export const StateArea = styled.div`
  background-color: #f1f1f1;
  height: 50px;
  border-radius: 10px;
  padding: 0px 10px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

// export const SwitchArea = styled.div`
//   background-color: #f1f1f1;
//   height: 40px;
//   border-radius: 10px;
//   padding: 0px 5px;
//   display: flex;
//   align-items: center;
//   width: 110px;
//   justify-content: space-around;
//   margin-right: 15px;
// `;

export const SwitchArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 110px;
  height: 44px;
  padding: 0px 5px;
  margin-right: 15px;
  border: 1px solid #E5E5E5;
  border-radius: 10px;
  /* background-color: #E8E8E8; */
  background-color: #F0F0F0;
`;

export const SearchArea = styled.div`
  color: #777;
  height: 50px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SearchIconArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 25px;
  padding: 5px;
  border: 1px solid #E5E5E5;
  border-radius: 10px 0px 0px 10px;
  border-right: none;
  /* background-color: #E8E8E8; */
  // background-color: #F0F0F0;
`;

// export const SearchInput = styled.input`
//   width: 200px;
//   font-size: 12px;
//   border: none;
//   outline: none;
//   background-color: #f1f1f1;
//   height: 35px;
//   border-radius: 15px;
//   padding: 0px 15px;
//   font-family: inherit;
// `;

export const SearchInput = styled.input`
  width: 200px;
  height: 35px;
  padding: 0px 15px;
  border: 1px solid #e5e5e5;
  border-left: none;
  border-radius: 0px 10px 10px 0px;
  outline: none;
  background-color: #FBFBFB;
  font-size: 12px;
  font-family: inherit;
`;

export const Input = styled.input`
    font-size: 12px;
    background-color: rgba(52, 52, 52, 0.1);
    border: none;
    outline: none;
    border-radius: 15px;
    padding: 10px;
    font-family: inherit;
`;

export const TextArea = styled.textarea`
  font-size: 12px;
  color: #FFFFFF;
  background-color: rgba(52, 52, 52, 0.1);
  border: none;
  outline: none;
  border-radius: 10px;
  padding: 15px;
  resize: none;
font-family: inherit;
  font-size: inherit;
  &::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  }
  &::-webkit-scrollbar-thumb {
      background-color: #333;
  } 
`;


/// FORMS //////////////////////////////////////////////////////////////////////

export const FormInputArea = styled.div`
display: flex;
//flex-direction: column;
margin: 6px;
`;

export const FormInputAreaInt = styled.div`
display: flex;
`;

export const FormInputLabel = styled.div`
align-items: center;
width: 100px;
justify-content: start;
font-size: 11px;
font-weight: bold;
color: #000;
display: flex;
padding: 0px 10px;
border-left: 3px solid #ccc;
height: 35px;
background-color: #f0f0f0;
border-radius: 3px 0px 0px 3px;
`;

export const FormInput = styled.input`
font-size: 12px;
border: none;
width: 100%;
outline: none;
background-color: #f5f5f5;
padding: 0px 10px;
font-family: inherit;
height: 35px;
border-radius: 0px 5px 5px 0px;
margin-right: 10px;
`;

export const FormSelect = styled.select`
font-size: 12px;
border: none;
width: 100%;
outline: none;
background-color: #f5f5f5;
padding: 0px 10px;
font-family: inherit;
height: 35px;
border-radius: 0px 5px 5px 0px;
margin-right: 10px;
`;


/// BADGE //////////////////////////////////////////////////////////////////////

export const badge = styled.div`
    margin-left: -20px;
    padding: 3px;
    border-radius: 50%;
    position: relative;
    color: #fff;
    margin-top: -25px;
    font-weight: bold;
    width: 15px;
    height: 15px;
    font-size: 11px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    box-shadow: 1px 1px 3px #333;
    z-index: 5;

`;


/// PUPUP //////////////////////////////////////////////////////////////////////

export const PopUpArea = styled.div`
    margin-left: -310px;
    padding: 20px;
    border-radius: 7px;
    opacity: 1;
    border: 2px solid orange;
    content: attr(aria-label);
    pointer-events: all;
    position: relative;
  //  transition: 0.5s ease-in-out;
    background-color: rgba(255, 255, 255, 255);
    margin-top: 100px;
    width: 250px;
    display: flex;
    flex-direction: column;
    box-shadow: 3px 3px 7px #ccc;
    z-index: 10;

`;

export const PopUpAreaFlex = styled.div`
    padding: 20px;
    border-radius: 7px;
    opacity: 1;
    border: 2px solid orange;
    content: attr(aria-label);
    pointer-events: all;
    position: relative;
    background-color: rgba(255, 255, 255, 255);
    width: 250px;
    display: flex;
    flex-direction: column;
    box-shadow: 3px 3px 7px #ccc;
    z-index: 10;

`;

export const PopUpCloseArea = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  border: 2px solid #ffb400;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  display: flex;
  background-color: #fff;
  top: -10px;
  right: -10px;
  cursor: pointer;
`;

export const PopUpBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
`;

export const PopUpBodyScroll = styled.div`
  display: block;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  padding: 7px;
  flex-direction: column;
  overflow-Y: scroll;
  overflow-X: hidden;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
      background-color: #e0e0e0;
  }
`;



/// MENU //////////////////////////////////////////////////////////////////////


export const Menu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 750px;
  padding: 7px;
  border-radius: 6px;
  color: #333;
  background-color: #CCC;
`;

export const AreaMenu = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: -30px;
`;

export const TrayMenu = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  justify-content: center;
  align-items: center;
`;

export const MenuSpecial = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 150px;
  padding: 7px;
  margin-right: -30px;
  border-radius: 6px;
  color: #333;
  background-color: #7137c8;
`;

export const PopUpAreaMenu = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  padding: 20px;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  margin-top: 120px;
  margin-left: -10px;
  opacity: 1;
  content: attr(aria-label);
  pointer-events: all;
  position: absolute;
  transition: 0.5s ease-in-out;
  background-color: #CCC;
  /* box-shadow: 3px 3px 7px #ccc; */
  z-index: 50;
`;

export const ButtonMenu = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 180px;
  height: 20px;
  padding: 10px;
  border-radius: 4px;
  margin: 0px 5px;
  cursor: pointer;
  &:hover {
    /* background-color: #F0F0F0; */
    /* background-color: #DADAFF; */
    /* background-color: #DEE3FD ; */
    background-color: #EDEDFF;
    transition: 0.25s ease-in-out;
  }
`;

export const TextButtonSubMenu = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
`;

export const ButtonMenuSpecial = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 180px;
  height: 20px;
  padding: 10px;
  border-radius: 4px;
  margin: 0px 5px;
  cursor: pointer;
`;

export const ButtonMenuExit = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  padding: 6px;
  border-radius: 4px;
  color: #FFF;
  cursor: pointer;
  &:hover {
    /* color: #7137C8; */
    background-color: #7137C8;
    transition: 0.25s ease-in-out;
  }
`;


/// USUÁRIO ////////////////////////////////////////////////////////////////////

export const IconArea = styled.div`
  width: 30px;
  height: 36px;
  justify-content: center;
  align-items: center;
`;

export const UserArea = styled.div`
  display: flex;
  color: #E0E0E0;
  align-items: center;
  justify-content: center;
  max-width: 300px;
  margin-right: 15px;
  cursor: pointer;
`;


/// QUEBRAS ////////////////////////////////////////////////////////////////////

/* Linha collapsed que quebrar para uma nova linha */
export const BreakLine = styled.div`
  flex-basis: 100%;
  height: 0;
`;
 
/* Coluna collapsed que quebrar para uma nova coluna */
export const BreakColumn = styled.div`
  flex-basis: 100%;
  width: 0;
`;


/// LOGO ///////////////////////////////////////////////////////////////////////

export const Logo = styled.img`
width: auto;
height: 170px;
margin-top: 150px;
`;